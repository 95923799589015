<ng-template #IconPlaylistAdd>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 7H10C10.2652 7 10.5196 6.89464 10.7071 6.70711C10.8946 6.51957 11 6.26522 11 6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5H4C3.73478 5 3.48043 5.10536 3.29289 5.29289C3.10536 5.48043 3 5.73478 3 6C3 6.26522 3.10536 6.51957 3.29289 6.70711C3.48043 6.89464 3.73478 7 4 7ZM13 10H15.5V12.5C15.5 12.7652 15.6054 13.0196 15.7929 13.2071C15.9804 13.3946 16.2348 13.5 16.5 13.5C16.7652 13.5 17.0196 13.3946 17.2071 13.2071C17.3946 13.0196 17.5 12.7652 17.5 12.5V10H20C20.2652 10 20.5196 9.89464 20.7071 9.70711C20.8946 9.51957 21 9.26522 21 9C21 8.73478 20.8946 8.48043 20.7071 8.29289C20.5196 8.10536 20.2652 8 20 8H17.5V5.5C17.5 5.23478 17.3946 4.98043 17.2071 4.79289C17.0196 4.60536 16.7652 4.5 16.5 4.5C16.2348 4.5 15.9804 4.60536 15.7929 4.79289C15.6054 4.98043 15.5 5.23478 15.5 5.5V8H13C12.7348 8 12.4804 8.10536 12.2929 8.29289C12.1054 8.48043 12 8.73478 12 9C12 9.26522 12.1054 9.51957 12.2929 9.70711C12.4804 9.89464 12.7348 10 13 10ZM4 13H10C10.2652 13 10.5196 12.8946 10.7071 12.7071C10.8946 12.5196 11 12.2652 11 12C11 11.7348 10.8946 11.4804 10.7071 11.2929C10.5196 11.1054 10.2652 11 10 11H4C3.73478 11 3.48043 11.1054 3.29289 11.2929C3.10536 11.4804 3 11.7348 3 12C3 12.2652 3.10536 12.5196 3.29289 12.7071C3.48043 12.8946 3.73478 13 4 13ZM20 17H4C3.73478 17 3.48043 17.1054 3.29289 17.2929C3.10536 17.4804 3 17.7348 3 18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18C21 17.7348 20.8946 17.4804 20.7071 17.2929C20.5196 17.1054 20.2652 17 20 17Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconHome>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.4701 9.31017L13.8501 2.70017C13.3389 2.25018 12.6812 2.00195 12.0001 2.00195C11.3191 2.00195 10.6614 2.25018 10.1501 2.70017L2.53013 9.31017C2.2976 9.5094 2.1302 9.77382 2.04958 10.0692C1.96897 10.3646 1.97884 10.6774 2.07793 10.9671C2.17702 11.2569 2.36077 11.5102 2.6054 11.6944C2.85003 11.8785 3.1443 11.9851 3.45013 12.0002H4.00013V19.0002C4.00013 19.7958 4.3162 20.5589 4.87881 21.1215C5.44142 21.6841 6.20448 22.0002 7.00013 22.0002H10.0001V16.0002H14.0001V22.0002H17.0001C17.7958 22.0002 18.5588 21.6841 19.1214 21.1215C19.6841 20.5589 20.0001 19.7958 20.0001 19.0002V12.0002H20.5501C20.856 11.9851 21.1502 11.8785 21.3949 11.6944C21.6395 11.5102 21.8232 11.2569 21.9223 10.9671C22.0214 10.6774 22.0313 10.3646 21.9507 10.0692C21.8701 9.77382 21.7027 9.5094 21.4701 9.31017Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconChevronDown>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.0002 15.0002C11.8686 15.0009 11.7381 14.9757 11.6163 14.926C11.4944 14.8762 11.3836 14.8029 11.2902 14.7102L7.29019 10.7102C7.10188 10.5219 6.99609 10.2665 6.99609 10.0002C6.99609 9.73388 7.10188 9.47849 7.29019 9.29018C7.47849 9.10188 7.73388 8.99609 8.00019 8.99609C8.26649 8.99609 8.52188 9.10188 8.71019 9.29018L12.0002 12.5902L15.2902 9.29018C15.4785 9.10188 15.7339 8.99609 16.0002 8.99609C16.2665 8.99609 16.5219 9.10188 16.7102 9.29018C16.8985 9.47849 17.0043 9.73388 17.0043 10.0002C17.0043 10.2665 16.8985 10.5219 16.7102 10.7102L12.7102 14.7102C12.6167 14.8029 12.5059 14.8762 12.3841 14.926C12.2623 14.9757 12.1318 15.0009 12.0002 15.0002Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconChevronLeft>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.9999 17.0002C13.8683 17.0009 13.7379 16.9757 13.616 16.926C13.4942 16.8762 13.3834 16.8029 13.2899 16.7102L9.28994 12.7102C9.19621 12.6172 9.12182 12.5066 9.07105 12.3848C9.02028 12.2629 8.99414 12.1322 8.99414 12.0002C8.99414 11.8682 9.02028 11.7375 9.07105 11.6156C9.12182 11.4937 9.19621 11.3831 9.28994 11.2902L13.2899 7.29019C13.3832 7.19695 13.4939 7.12299 13.6157 7.07253C13.7375 7.02207 13.8681 6.99609 13.9999 6.99609C14.1318 6.99609 14.2624 7.02207 14.3842 7.07253C14.506 7.12299 14.6167 7.19695 14.7099 7.29019C14.8032 7.38342 14.8771 7.49411 14.9276 7.61594C14.9781 7.73776 15.004 7.86833 15.004 8.00019C15.004 8.13204 14.9781 8.26261 14.9276 8.38444C14.8771 8.50626 14.8032 8.61695 14.7099 8.71019L11.4099 12.0002L14.7099 15.2902C14.8037 15.3831 14.8781 15.4937 14.9288 15.6156C14.9796 15.7375 15.0057 15.8682 15.0057 16.0002C15.0057 16.1322 14.9796 16.2629 14.9288 16.3848C14.8781 16.5066 14.8037 16.6172 14.7099 16.7102C14.6165 16.8029 14.5057 16.8762 14.3838 16.926C14.262 16.9757 14.1315 17.0009 13.9999 17.0002Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconChevronRight>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.99994 17.0002C9.86833 17.0009 9.73787 16.9757 9.61603 16.926C9.4942 16.8762 9.38338 16.8029 9.28994 16.7102C9.19621 16.6172 9.12182 16.5066 9.07105 16.3848C9.02028 16.2629 8.99414 16.1322 8.99414 16.0002C8.99414 15.8682 9.02028 15.7375 9.07105 15.6156C9.12182 15.4937 9.19621 15.3831 9.28994 15.2902L12.5899 12.0002L9.28994 8.71019C9.10164 8.52188 8.99585 8.26649 8.99585 8.00019C8.99585 7.86833 9.02182 7.73776 9.07228 7.61594C9.12274 7.49411 9.1967 7.38342 9.28994 7.29019C9.38318 7.19695 9.49387 7.12299 9.61569 7.07253C9.73751 7.02207 9.86808 6.99609 9.99994 6.99609C10.2662 6.99609 10.5216 7.10188 10.7099 7.29019L14.7099 11.2902C14.8037 11.3831 14.8781 11.4937 14.9288 11.6156C14.9796 11.7375 15.0057 11.8682 15.0057 12.0002C15.0057 12.1322 14.9796 12.2629 14.9288 12.3848C14.8781 12.5066 14.8037 12.6172 14.7099 12.7102L10.7099 16.7102C10.6165 16.8029 10.5057 16.8762 10.3838 16.926C10.262 16.9757 10.1315 17.0009 9.99994 17.0002Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconChevronUp>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.0002 14.9999C15.8686 15.0007 15.7381 14.9755 15.6163 14.9257C15.4944 14.8759 15.3836 14.8026 15.2902 14.7099L12.0002 11.4099L8.71019 14.7099C8.52188 14.8982 8.26649 15.004 8.00019 15.004C7.73388 15.004 7.47849 14.8982 7.29019 14.7099C7.10188 14.5216 6.99609 14.2662 6.99609 13.9999C6.99609 13.8681 7.02207 13.7375 7.07253 13.6157C7.12299 13.4939 7.19695 13.3832 7.29019 13.2899L11.2902 9.28994C11.3831 9.19621 11.4937 9.12182 11.6156 9.07105C11.7375 9.02028 11.8682 8.99414 12.0002 8.99414C12.1322 8.99414 12.2629 9.02028 12.3848 9.07105C12.5066 9.12182 12.6172 9.19621 12.7102 9.28994L16.7102 13.2899C16.8039 13.3829 16.8783 13.4935 16.9291 13.6154C16.9798 13.7372 17.006 13.8679 17.006 13.9999C17.006 14.132 16.9798 14.2627 16.9291 14.3845C16.8783 14.5064 16.8039 14.617 16.7102 14.7099C16.6167 14.8026 16.5059 14.8759 16.3841 14.9257C16.2623 14.9755 16.1318 15.0007 16.0002 14.9999Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconEditPen>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.4999 4.5C18.8363 3.83805 17.9372 3.46631 16.9999 3.46631C16.0626 3.46631 15.1635 3.83805 14.4999 4.5L14.2099 4.79L19.2099 9.79L19.4999 9.5C20.1618 8.83638 20.5336 7.93732 20.5336 7C20.5336 6.06268 20.1618 5.16362 19.4999 4.5ZM5.5499 13.5C5.19016 13.8617 4.92859 14.3091 4.7899 14.8L3.7899 18.45C3.72963 18.6998 3.73453 18.9608 3.80413 19.2082C3.87373 19.4555 4.0057 19.6808 4.18739 19.8625C4.36907 20.0442 4.59438 20.1762 4.84172 20.2458C5.08905 20.3154 5.35012 20.3203 5.5999 20.26L9.2499 19.21C9.7408 19.0713 10.1882 18.8097 10.5499 18.45L17.7899 11.21L12.7899 6.21L5.5499 13.5Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconSearch>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.004 2.00001C11.3023 2.0014 12.5746 2.36381 13.6787 3.04676C14.7829 3.7297 15.6753 4.70625 16.2564 5.86727C16.8374 7.02829 17.0841 8.32801 16.9689 9.62118C16.8537 10.9143 16.3811 12.15 15.604 13.19L21.714 19.29C21.8077 19.383 21.8821 19.4936 21.9329 19.6154C21.9836 19.7373 22.0098 19.868 22.0098 20C22.0098 20.132 21.9836 20.2627 21.9329 20.3846C21.8821 20.5064 21.8077 20.617 21.714 20.71C21.621 20.8037 21.5104 20.8781 21.3885 20.9289C21.2667 20.9797 21.136 21.0058 21.004 21.0058C20.872 21.0058 20.7412 20.9797 20.6194 20.9289C20.4975 20.8781 20.3869 20.8037 20.294 20.71L14.194 14.6C13.3063 15.2658 12.2731 15.7109 11.1795 15.8989C10.086 16.0868 8.96341 16.0121 7.90441 15.6808C6.84542 15.3496 5.88033 14.7714 5.08874 13.9939C4.29715 13.2163 3.70174 12.2617 3.35161 11.2088C3.00148 10.156 2.90667 9.03491 3.07498 7.93816C3.2433 6.84142 3.66992 5.8004 4.31967 4.90095C4.96942 4.0015 5.82368 3.2694 6.812 2.76502C7.80032 2.26064 8.89438 1.99842 10.004 2.00001ZM10.004 14C10.9929 14 11.9596 13.7068 12.7818 13.1574C13.6041 12.6079 14.2449 11.8271 14.6234 10.9134C15.0018 9.99979 15.1008 8.99446 14.9079 8.02456C14.715 7.05465 14.2388 6.16374 13.5395 5.46447C12.8402 4.76521 11.9493 4.28901 10.9794 4.09608C10.0095 3.90315 9.00418 4.00217 8.09055 4.38061C7.17692 4.75905 6.39603 5.39991 5.84662 6.22216C5.29721 7.0444 5.00397 8.0111 5.00397 9.00001C5.00397 9.65662 5.1333 10.3068 5.38457 10.9134C5.63584 11.5201 6.00414 12.0712 6.46843 12.5355C6.93273 12.9998 7.48392 13.3681 8.09055 13.6194C8.69718 13.8707 9.34736 14 10.004 14Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconLogout>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.87 3.91C17.7637 3.83225 17.6431 3.77626 17.5152 3.74523C17.3872 3.7142 17.2544 3.70875 17.1243 3.72919C16.9942 3.74963 16.8695 3.79556 16.7572 3.86434C16.6449 3.93312 16.5473 4.0234 16.47 4.13C16.3923 4.23627 16.3363 4.35685 16.3052 4.48482C16.2742 4.61278 16.2687 4.74562 16.2892 4.8757C16.3096 5.00578 16.3556 5.13054 16.4243 5.24282C16.4931 5.3551 16.5834 5.4527 16.69 5.53C18.0597 6.52119 19.0802 7.92085 19.6049 9.52809C20.1297 11.1353 20.1317 12.8675 19.6108 14.476C19.0899 16.0844 18.0727 17.4865 16.7053 18.4809C15.338 19.4754 13.6907 20.011 12 20.011C10.3093 20.011 8.66201 19.4754 7.29465 18.4809C5.92729 17.4865 4.91015 16.0844 4.38921 14.476C3.86827 12.8675 3.87032 11.1353 4.39507 9.52809C4.91982 7.92085 5.94029 6.52119 7.31 5.53C7.52483 5.37352 7.66869 5.13811 7.70995 4.87556C7.75121 4.61301 7.68648 4.34483 7.53 4.13C7.37352 3.91517 7.13811 3.77131 6.87556 3.73005C6.61301 3.68879 6.34483 3.75352 6.13 3.91C4.41965 5.15011 3.14598 6.90003 2.49169 8.90878C1.83739 10.9175 1.83608 13.0819 2.48794 15.0914C3.1398 17.101 4.41134 18.8524 6.12019 20.0946C7.82903 21.3368 9.88738 22.0059 12 22.0059C14.1126 22.0059 16.171 21.3368 17.8798 20.0946C19.5887 18.8524 20.8602 17.101 21.5121 15.0914C22.1639 13.0819 22.1626 10.9175 21.5083 8.90878C20.854 6.90003 19.5804 5.15011 17.87 3.91ZM12 8C12.2652 8 12.5196 7.89464 12.7071 7.70711C12.8946 7.51957 13 7.26522 13 7V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V7C11 7.26522 11.1054 7.51957 11.2929 7.70711C11.4804 7.89464 11.7348 8 12 8Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconProfile>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            clip-rule="evenodd"
            d="M16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6ZM20 16.5C20 19.5376 16.4183 22 12 22C7.58172 22 4 19.5376 4 16.5C4 13.4624 7.58172 11 12 11C16.4183 11 20 13.4624 20 16.5Z"
            fill="currentcolor"
            fill-rule="evenodd"
        ></path>
    </svg>
</ng-template>

<ng-template #IconArrowBack>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.28994 12.7099L8.28994 18.7099C8.38318 18.8032 8.49387 18.8771 8.61569 18.9276C8.73751 18.9781 8.86808 19.004 8.99994 19.004C9.1318 19.004 9.26237 18.9781 9.38419 18.9276C9.50601 18.8771 9.6167 18.8032 9.70994 18.7099C9.80318 18.6167 9.87714 18.506 9.9276 18.3842C9.97806 18.2624 10.004 18.1318 10.004 17.9999C10.004 17.8681 9.97806 17.7375 9.9276 17.6157C9.87714 17.4939 9.80318 17.3832 9.70994 17.2899L5.40994 12.9999H20.9999C21.2652 12.9999 21.5195 12.8946 21.707 12.707C21.8946 12.5195 21.9999 12.2652 21.9999 11.9999C21.9999 11.7347 21.8946 11.4804 21.707 11.2928C21.5195 11.1053 21.2652 10.9999 20.9999 10.9999H5.40994L9.70994 6.70994C9.80367 6.61698 9.87806 6.50638 9.92883 6.38452C9.9796 6.26266 10.0057 6.13195 10.0057 5.99994C10.0057 5.86793 9.9796 5.73722 9.92883 5.61536C9.87806 5.4935 9.80367 5.3829 9.70994 5.28994C9.61698 5.19621 9.50637 5.12182 9.38452 5.07105C9.26266 5.02028 9.13195 4.99414 8.99994 4.99414C8.86793 4.99414 8.73722 5.02028 8.61536 5.07105C8.4935 5.12182 8.3829 5.19621 8.28994 5.28994L2.28994 11.2899C2.19621 11.3829 2.12182 11.4935 2.07105 11.6154C2.02028 11.7372 1.99414 11.8679 1.99414 11.9999C1.99414 12.132 2.02028 12.2627 2.07105 12.3845C2.12182 12.5064 2.19621 12.617 2.28994 12.7099Z"
            fill="currentcolor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconJioDot>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#ic_jio_dot_svg__clip0_5207_79934)">
            <rect width="24" height="24" rx="12" fill="currentColor"></rect>
            <path
                d="M8.478 7.237h-.4c-.76 0-1.174.428-1.174 1.285v4.129c0 1.063-.359 1.436-1.201 1.436-.663 0-1.202-.29-1.63-.815-.041-.055-.91.36-.91 1.381 0 1.105 1.034 1.782 2.955 1.782 2.333 0 3.563-1.174 3.563-3.742V8.521c-.002-.856-.416-1.285-1.203-1.285zm9.3 2.017c-2.265 0-3.77 1.436-3.77 3.577 0 2.196 1.45 3.605 3.728 3.605 2.265 0 3.756-1.409 3.756-3.59.001-2.156-1.477-3.592-3.714-3.592zm-.028 5.15c-.884 0-1.491-.648-1.491-1.574 0-.91.622-1.56 1.491-1.56.87 0 1.491.65 1.491 1.574 0 .898-.634 1.56-1.49 1.56zm-5.656-5.082h-.277c-.676 0-1.187.318-1.187 1.285v4.419c0 .98.497 1.285 1.215 1.285h.277c.676 0 1.16-.332 1.16-1.285v-4.42c0-.993-.47-1.284-1.188-1.284zm-.152-3.203c-.856 0-1.395.484-1.395 1.243 0 .773.553 1.256 1.436 1.256.857 0 1.395-.483 1.395-1.256s-.552-1.243-1.436-1.243z"
                fill="#fff"
            ></path>
        </g>
        <defs>
            <clipPath id="ic_jio_dot_svg__clip0_5207_79934">
                <path fill="#fff" d="M0 0h24v24H0z"></path>
            </clipPath>
        </defs>
    </svg>
</ng-template>

<ng-template #IconErrorColoured>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" fill="#fff"></circle>
        <path
            d="M12 2a10 10 0 100 20 10 10 0 000-20zm3.71 12.29a1.002 1.002 0 01-.325 1.639 1 1 0 01-1.095-.219L12 13.41l-2.29 2.3a1 1 0 01-1.639-.325 1 1 0 01.219-1.095l2.3-2.29-2.3-2.29a1.004 1.004 0 011.42-1.42l2.29 2.3 2.29-2.3a1.004 1.004 0 011.42 1.42L13.41 12l2.3 2.29z"
            fill="#F50031"
        ></path>
    </svg>
</ng-template>

<ng-template #IconSuccessColoured>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" fill="#fff"></circle>
        <path
            d="M12 2a10 10 0 100 20 10 10 0 000-20zm5.21 7.71l-6 6a1.002 1.002 0 01-1.42 0l-3-3a1.003 1.003 0 111.42-1.42l2.29 2.3 5.29-5.3a1.004 1.004 0 011.42 1.42z"
            fill="#25AB21"
        ></path>
    </svg>
</ng-template>

<ng-template #IconTrash>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20 6h-2V5a3 3 0 00-3-3H9a3 3 0 00-3 3v1H4a1 1 0 000 2h1v11a3 3 0 003 3h8a3 3 0 003-3V8h1a1 1 0 100-2zM9 18a1 1 0 11-2 0v-7a1 1 0 112 0v7zm4 0a1 1 0 01-2 0v-7a1 1 0 012 0v7zm4 0a1 1 0 01-2 0v-7a1 1 0 012 0v7zM8 5a1 1 0 011-1h6a1 1 0 011 1v1H8V5z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconList>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20 17H9a1 1 0 000 2h11a1 1 0 000-2zm0-6H9a1 1 0 000 2h11a1 1 0 000-2zM9 7h11a1 1 0 100-2H9a1 1 0 000 2zM4.5 4.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #Icon404Error>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 12a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zm7-8H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm-8 2a1 1 0 110 2 1 1 0 010-2zM4 7a1 1 0 112 0 1 1 0 01-2 0zm4.5 8H8v.5a.5.5 0 01-1 0V15H5.5a.52.52 0 01-.41-.21.48.48 0 01-.09-.45l1-3a.5.5 0 01.94.32L6.19 14H7v-.5a.5.5 0 011 0v.5h.5a.5.5 0 010 1zM8 8a1 1 0 110-2 1 1 0 010 2zm6 6a2 2 0 01-4 0v-1a2 2 0 014 0v1zm4.5 1H18v.5a.5.5 0 01-1 0V15h-1.5a.52.52 0 01-.41-.21.48.48 0 01-.06-.45l1-3a.5.5 0 11.94.32L16.19 14H17v-.5a.5.5 0 011 0v.5h.5a.5.5 0 010 1z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconExport>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.71 6.71L11 5.41V16a1 1 0 102 0V5.41l1.29 1.3a1 1 0 001.639-.325 1 1 0 00-.219-1.095l-3-3a1 1 0 00-1.42 0l-3 3a1.004 1.004 0 001.42 1.42zM20 12a1 1 0 00-1 1v6a1 1 0 01-1 1H6a1 1 0 01-1-1v-6a1 1 0 00-2 0v6a3 3 0 003 3h12a3 3 0 003-3v-6a1 1 0 00-1-1z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconVisible>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.69 10.74A10.87 10.87 0 0012 5a10.87 10.87 0 00-9.69 5.74 2.74 2.74 0 000 2.52A10.87 10.87 0 0012 19a10.87 10.87 0 009.69-5.74 2.74 2.74 0 000-2.52zm-9.1 4.2a3 3 0 11-1.183-5.881 3 3 0 011.183 5.881z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconVisibleOff>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.69 10.74A10.539 10.539 0 0018.41 7l-3.7 3.69a3 3 0 01-4 4L20.49 5a1.052 1.052 0 00-.342-1.719A1.053 1.053 0 0019 3.51L3.51 19a1.052 1.052 0 001.148 1.718c.128-.053.244-.13.342-.228L7.42 18c1.439.652 3 .993 4.58 1a10.87 10.87 0 009.69-5.74 2.74 2.74 0 000-2.52zm-7.23-5.45A11 11 0 0012 5a10.87 10.87 0 00-9.69 5.74 2.74 2.74 0 000 2.52c.469.889 1.061 1.707 1.76 2.43l10.39-10.4z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconCopy>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 8H5a3 3 0 00-3 3v8a3 3 0 003 3h8a3 3 0 003-3v-8a3 3 0 00-3-3zm6-6h-8a3 3 0 00-3 3v1h5a5 5 0 015 5v5h1a3 3 0 003-3V5a3 3 0 00-3-3z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconCalendar>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 3h-1a1 1 0 00-2 0H9a1 1 0 00-2 0H6a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3zm-4.5 14a1 1 0 01-2 0v-3.59l-.29.3a1.004 1.004 0 11-1.42-1.42l2-2a.999.999 0 011.09-.21 1 1 0 01.62.92v6zM19 7H5V6a1 1 0 011-1h1a1 1 0 002 0h6a1 1 0 002 0h1a1 1 0 011 1v1z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconTime>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 2a10 10 0 100 20 10 10 0 000-20zm1 11a1 1 0 01-1 1H9a1 1 0 010-2h2V9a1 1 0 012 0v4z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconSuccess>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 2a10 10 0 100 20 10 10 0 000-20zm5.21 7.71l-6 6a1.002 1.002 0 01-1.42 0l-3-3a1.003 1.003 0 111.42-1.42l2.29 2.3 5.29-5.3a1.004 1.004 0 011.42 1.42z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconClose>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.41 12l6.3-6.29a1.004 1.004 0 00-1.42-1.42L12 10.59l-6.29-6.3a1.004 1.004 0 10-1.42 1.42l6.3 6.29-6.3 6.29a.999.999 0 000 1.42 1 1 0 001.42 0l6.29-6.3 6.29 6.3a1.001 1.001 0 001.639-.325 1 1 0 00-.22-1.095L13.41 12z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconInfo>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 3.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm2 12h-4a1 1 0 010-2h1v-3h-1a1 1 0 010-2h2a1 1 0 011 1v4h1a1 1 0 010 2z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconAddCircle>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 2a10 10 0 100 20 10 10 0 000-20zm4 11h-3v3a1 1 0 01-2 0v-3H8a1 1 0 010-2h3V8a1 1 0 012 0v3h3a1 1 0 010 2z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconMinusCircle>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm4 11H8a1 1 0 010-2h8a1 1 0 010 2z" fill="currentColor"></path>
    </svg>
</ng-template>

<ng-template #IconImage>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.5 7a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM18 3H6a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3zm1 9.09l-2.79-2.8a1 1 0 00-1.42 0l-4.29 4.3-1.29-1.3a1 1 0 00-1.42 0L5 15.09V6a1 1 0 011-1h12a1 1 0 011 1v6.09z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<ng-template #IconRefresh>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 4a8 8 0 013.85 1H15a1 1 0 100 2h3a1 1 0 001-1V3a1 1 0 00-2 0v.36A10 10 0 0012 2a10 10 0 00-8.65 5A9.94 9.94 0 002 12a1 1 0 102 0 8 8 0 018-8zm9.71 7.29A1 1 0 0020 12a8 8 0 01-11.84 7H9a1 1 0 000-2H6a1 1 0 00-1 1v3a1 1 0 102 0v-.36A10 10 0 0012 22a10 10 0 0010-10 1 1 0 00-.29-.71z"
            fill="currentColor"
        ></path>
    </svg>
</ng-template>

<!-- <ng-template #IconAdd>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 11h-7V4a1 1 0 00-2 0v7H4a1 1 0 000 2h7v7a1 1 0 002 0v-7h7a1 1 0 000-2z" fill="currentColor"></path>
    </svg>
</ng-template> -->

<!-- <ng-template #IconMinus>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.293 11.293A1 1 0 014 11h16a1 1 0 010 2H4a1 1 0 01-.707-1.707z" fill="currentColor"></path>
    </svg>
</ng-template> -->

<span
    class="j-icon"
    [ngClass]="isBg ? 'j-icon--bg' : ''"
    [style]="getStyles()"
    [attr.id]="id ? id : null"
    [attr.aria-label]="ic ? 'icon ' + ic : 'icon'"
    role="img"
>
    <ng-container *ngIf="ic == 'IcPlaylistAdd'">
        <ng-container *ngTemplateOutlet="IconPlaylistAdd"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcHome'">
        <ng-container *ngTemplateOutlet="IconHome"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcChevronDown'">
        <ng-container *ngTemplateOutlet="IconChevronDown"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcChevronLeft'">
        <ng-container *ngTemplateOutlet="IconChevronLeft"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcChevronRight'">
        <ng-container *ngTemplateOutlet="IconChevronRight"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcChevronUp'">
        <ng-container *ngTemplateOutlet="IconChevronUp"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcEditPen'">
        <ng-container *ngTemplateOutlet="IconEditPen"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcSearch'">
        <ng-container *ngTemplateOutlet="IconSearch"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcLogout'">
        <ng-container *ngTemplateOutlet="IconLogout"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcProfile'">
        <ng-container *ngTemplateOutlet="IconProfile"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcArrowBack'">
        <ng-container *ngTemplateOutlet="IconArrowBack"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcJioDot'">
        <ng-container *ngTemplateOutlet="IconJioDot"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcErrorColored'">
        <ng-container *ngTemplateOutlet="IconErrorColoured"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcSuccessColored'">
        <ng-container *ngTemplateOutlet="IconSuccessColoured"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcTrash'">
        <ng-container *ngTemplateOutlet="IconTrash"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcList'">
        <ng-container *ngTemplateOutlet="IconList"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'Ic404Error'">
        <ng-container *ngTemplateOutlet="Icon404Error"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcExport'">
        <ng-container *ngTemplateOutlet="IconExport"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcVisible'">
        <ng-container *ngTemplateOutlet="IconVisible"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcVisibleOff'">
        <ng-container *ngTemplateOutlet="IconVisibleOff"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcCopy'">
        <ng-container *ngTemplateOutlet="IconCopy"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcCalendar'">
        <ng-container *ngTemplateOutlet="IconCalendar"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcTime'">
        <ng-container *ngTemplateOutlet="IconTime"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcSuccess'">
        <ng-container *ngTemplateOutlet="IconSuccess"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcClose'">
        <ng-container *ngTemplateOutlet="IconClose"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcInfo'">
        <ng-container *ngTemplateOutlet="IconInfo"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcAddCircle'">
        <ng-container *ngTemplateOutlet="IconAddCircle"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcMinusCircle'">
        <ng-container *ngTemplateOutlet="IconMinusCircle"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcImage'">
        <ng-container *ngTemplateOutlet="IconImage"></ng-container>
    </ng-container>
    <ng-container *ngIf="ic == 'IcRefresh'">
        <ng-container *ngTemplateOutlet="IconRefresh"></ng-container>
    </ng-container>
</span>
