import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
    @Input() labelText: string = '';
    @Input() value: any;
    @Input() checked: any = false;
    @Input() disabled: any = false;
    @Input() disable!: boolean;
    @Output() checkboxChange = new EventEmitter<any>();

    ngOnInit() {}

    checkboxChanged(event: any) {
        this.checkboxChange.emit({
            checked: event.target.checked,
            value: this.value,
        });
    }

    setCheckboxLock(event: any) {
        if (this.disabled) {
            event.preventDefault();
        }
    }
}
