import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from './text-field.component';
import { FormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon.module';

@NgModule({
    declarations: [TextFieldComponent],
    imports: [CommonModule, FormsModule, IconModule],
    exports: [TextFieldComponent],
})
export class TextFieldModule {}
