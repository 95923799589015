import { Component, ComponentFactoryResolver, Input, ViewChild } from '@angular/core';
import { ModalhostDirective } from 'src/app/directives/modalhost/modalhost.directive';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { ModalService } from './modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
    @Input() modalData: any;
    @Input() showSpinner: boolean = true;

    @ViewChild(ModalhostDirective, { static: true })
    modalHost!: ModalhostDirective;

    constructor(public modalService: ModalService, private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit() {}

    ngOnChanges() {
        this.loadModalComponent();
    }

    loadModalComponent() {
        const modalItem = this.modalData;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalItem.component);
        const viewContainerRef = this.modalHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent<any>(componentFactory);

        switch (modalItem.prefix) {
            case 'audit-table-data':
                componentRef.instance.data = modalItem.modalData;
                break;
            case 'audit-delete-confirm':
                componentRef.instance.data = modalItem.modalData;
                break;
            case 'category-visible-table-data':
                componentRef.instance.data = modalItem.modalData;
                break;
            case 'multilinguist-data':
                componentRef.instance.data = modalItem.modalData;
                break;
            case 'language-available-data':
                componentRef.instance.data = modalItem.modalData;
                break;
            case 'image-modal-data':
                componentRef.instance.data = modalItem.modalData;
                break;
        }
    }

    getModalDialogModifierClass(prefix: string) {
        switch (prefix) {
            case 'audit-table-data':
                return 'modal-container__dialog--audit-table-data';
                break;
            case 'audit-delete-confirm':
                return 'modal-container__dialog--audit-delete-confirm';
                break;
            case 'category-visible-table-data':
                return 'modal-container__dialog--category-visible-table-data';
                break;
            case 'multilinguist-data':
                return 'modal-container__dialog--multilinguist-data';
                break;
            case 'language-available-data':
                return 'modal-container__dialog--language-available-data';
                break;
            case 'image-modal-data':
                return 'modal-container__dialog--image-modal-data';
                break;
        }
        return '';
    }

    closeModal() {
        this.modalService.closeModal();
    }
}
