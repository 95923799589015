import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AES, enc } from 'crypto-js';
import { Subject, Observable } from 'rxjs';
import { tap, catchError, shareReplay } from 'rxjs/operators';
import { AppConstants } from '../constants/app-constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private authData: any;
    private authSubject = new Subject();

    auth$: Observable<any> = this.authSubject.asObservable();

    constructor(private http: HttpService) {}

    login(username: string, password: string, captcha: string) {
        const key = enc.Utf8.parse('@virtualization@');
        const iv = enc.Utf8.parse('alphanumerically');
        const encryptedData = AES.encrypt(password, key, { iv: iv });

        // let url = `${environment.apiUrlDomain}/auth/login`;

        let url = `${environment.apiUrlDomain}/auth/login`;

        let headers = {
            'Content-Type': 'application/json',
        };

        let data = {
            username: username,
            password: encryptedData.toString(),
            captcha: captcha,
        };

        this.http
            .postRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    console.log('login data:', response);
                    if (response.status == 201) {
                        this.setAuthData(response.body);
                        this.authSubject.next({
                            type: 'LOGIN_SUCCESSFUL',
                            data: response.data,
                        });
                    }
                }),
                catchError((error) => {
                    this.authSubject.next({
                        type: 'LOGIN_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    convertImageToBase64(imageData: any) {
        // Convert binary data to base64
        let base64String = btoa(String.fromCharCode.apply(null, imageData));
        // Remove spaces from the base64 string
        base64String = base64String.replace(/\s/g, '');
        return base64String;
    }

    getCaptcha() {
        let headers = {};
        this.http
            .fetchCaptcha(`${environment.apiUrlDomain}/captcha`, headers)
            .pipe(
                tap((response: any) => {
                    let imageData = new Uint8Array(response.body);
                    let base64Image = this.convertImageToBase64(imageData);

                    const captchaImage = `data:image/png;base64,${base64Image}`;

                    this.authSubject.next({
                        type: 'LOGIN_CAPTCHA_SUCCESSFUL',
                        data: captchaImage,
                    });
                }),
                catchError((error) => {
                    this.authSubject.next({
                        type: 'LOGIN_CAPTCHA_FAILED',
                        data: error,
                    });
                    throw error;
                })
            )
            .subscribe();
    }

    getAuthData() {
        let storedAuthData = localStorage.getItem('authData');
        if (storedAuthData != null) {
            return JSON.parse(storedAuthData);
        }
        return this.authData;
    }

    private setAuthData(authData: any) {
        localStorage.setItem('authData', JSON.stringify(authData));
        this.authData = authData;
    }

    clearAuthData() {
        localStorage.removeItem('authData');
        this.authData = undefined;
    }
}
