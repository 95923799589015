import { Component, Input } from '@angular/core';
import { ModalService } from '../modal.service';
import { CategoryService } from 'src/app/pages/category/category.service';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../spinner/spinner.service';
import { FileUploadService } from 'src/app/utils/file-upload-service';

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.component.html',
    styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
    fileUploadSubscription$?: Subscription;
    @Input() data: any;
    @Input() showSpinner: boolean = false;
    uploadedFile: any;
    filename: string = '';
    imgSrc: any;
    categoryId: any;
    itemId: any;
    source: any;
    type: any;
    private fileReader!: FileReader;
    imageModalData: any;
    submitId: any;
    uploadedImagesBannerArtDictionary: any = {};
    categorySubscription$?: Subscription;
    dataFetched: boolean = false;
    imageType: any;
    constructor(
        private modal: ModalService,
        private categoryService: CategoryService,
        private spinner: SpinnerService,
        private fileUploadService: FileUploadService
    ) {
        this.fileReader = new FileReader();
        this.fileReader.addEventListener(
            'load',
            () => {
                let newIndex = this.imageModalData.filter((res: any) => !res._id).length;
                console.log('this.uploadedImagesBannerArtDictionary', this.uploadedImagesBannerArtDictionary);

                // convert image file to base64 string
                // this.imgSrc = this.fileReader.result;
                this.imageModalData.unshift({
                    categoryId: this.categoryId,
                    itemId: this.itemId,
                    source: this.source,
                    type: this.type,
                    image: this.fileReader.result,
                    index: newIndex + 1,
                });

                if (this.data[0].arg[5] == 'Landscape') {
                    this.imageType = 'bannerArt';
                } else if (this.data[0].arg[5] == 'Portrait') {
                    this.imageType = 'albumArt';
                } else if (this.data[0].arg[5] == 'Square') {
                    this.imageType = 'squareArt';
                }

                // this.fileUploadService.getUploadedImagePath('metadata', 'bannerArt', this.uploadedFile);
                if (this.data[0].arg[3] == 'Deeplink') {
                    this.fileUploadService.getUploadedImagePath('deeplink', this.imageType, this.uploadedFile);
                } else if (this.data[0].arg[3] == 'Cpp') {
                    this.fileUploadService.getUploadedImagePath('metadata', this.imageType, this.uploadedFile);
                } else if (this.data[0].arg[3] == 'Category') {
                    this.fileUploadService.getUploadedImagePath('category', this.imageType, this.uploadedFile);
                } else if (this.data[0].arg[3] == 'Epg') {
                    if (this.data[0].arg[4] == 'Episode' || this.data[0].arg[4] == 'Program') {
                        this.fileUploadService.getUploadedImagePath('epgdata', this.imageType, this.uploadedFile);
                    } else if (this.data[0].arg[4] == 'Channel') {
                        this.fileUploadService.getUploadedImagePath('channel', this.imageType, this.uploadedFile);
                    }
                }
            },
            false
        );
    }

    ngOnInit() {
        console.log(this.data);
        this.categoryId = this.data[0].arg[1];
        this.itemId = this.data[0].arg[0];
        this.source = this.data[0].arg[3];
        this.type = this.data[0].arg[4];

        this.categorySubscription$ = this.categoryService.category$.subscribe((params: any) => {
            if (params) {
                switch (params.type) {
                    case 'IMAGE_MODAL_DATA_SUCCESSFUL':
                        this.showSpinner = true;

                        console.log(params.data);
                        this.imageModalData = params.data;
                        // this.spinner.destroySpinner();

                        setTimeout(() => {
                            this.showSpinner = false;
                        }, 2000);
                        this.dataFetched = true;
                        break;
                    case 'SUBMIT_IMAGE_MODAL_DATA_SUCCESSFUL':
                        console.log(params.data);
                        if (params.data.source == 'Category') {
                            this.categoryService.getCategoryCollectionData(params.data.categoryId);
                        } else {
                            this.categoryService.getCategoryContentData(params.data.categoryId);
                        }
                        // this.categoryService.getCategoryContentData(params.data.categoryId);
                        setTimeout(() => {
                            this.modal.closeModal();
                        }, 2000);
                        break;
                }
            }
        });

        this.fileUploadSubscription$ = this.fileUploadService.fileUpload$.subscribe((params: any) => {
            if (params) {
                switch (params.type) {
                    case 'FILE_UPLOAD_IMAGE_PATH_SUCCESSFUL':
                        console.log('sFJcjasfjandfjlandv', params.data, this.imageModalData);
                        let indexOfCurrentImage = this.imageModalData?.filter((res: any) => !res._id)?.length;
                        this.uploadedImagesBannerArtDictionary[indexOfCurrentImage] =
                            params.data.bannerArt || params.data.albumArt || params.data.squareArt;
                        break;

                    case 'FILE_UPLOAD_IMAGE_PATH_FAILED':
                        console.log('ERROR', params.data.error.message);
                        break;
                }
            }
        });

        // JSON.stringify(this.data, null, '\t');
        // this.data = JSON.stringify(this.data, null, 4);
        // console.log('in image', this.data[0].arg);
        this.data[0].callback(this.data[0].arg[0], this.data[0].arg[1]);

        this.categoryService.getImageModalData(
            this.data[0].arg[0],
            this.data[0].arg[1],
            this.data[0].arg[3],
            this.data[0].arg[4]
        );
    }

    submit() {
        const matchedJson = this.imageModalData.find(
            (item: any) => (item._id && item._id == this.submitId) || item.index == this.submitId
        );
        console.log('matchedJson', matchedJson);

        let indexOfCurrentImage = this.imageModalData.filter((res: any) => !res._id).length;
        const data = {
            categoryId: matchedJson.categoryId,
            itemId: matchedJson.itemId,
            source: matchedJson.source,
            ...(matchedJson.type && { type: matchedJson.type }),
            ...(matchedJson.original && { original: matchedJson.original }),
            imageUrl: matchedJson._id ? matchedJson.image : this.uploadedImagesBannerArtDictionary[indexOfCurrentImage],
        };

        console.log('SUBMIT', data);

        this.categoryService.submitImageModalData(data);
    }

    radioChanged(event: any) {
        console.log('RADIO', event);
        this.submitId = event.value;
    }

    setImageData(event: any) {
        console.log('IMAGE DATA', event);
    }

    upload(uploadedFile: any) {
        this.uploadedFile = uploadedFile;
        this.filename = uploadedFile.name;
        this.fileReader.readAsDataURL(uploadedFile);
    }

    ngOnDestroy() {
        this.categorySubscription$?.unsubscribe();
    }
}
