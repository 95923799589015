import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { Subject, Observable } from 'rxjs';
import { tap, catchError, shareReplay, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { AppConstants } from 'src/app/constants/app-constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MultilingualService {
    private authData: any;
    private multilingualSubject = new Subject();

    multilingual$: Observable<any> = this.multilingualSubject.asObservable();

    constructor(private auth: AuthService, private http: HttpService) {
        this.authData = this.auth.getAuthData();
    }

    getMultilingualData() {
        const url = `${environment.apiUrlDomain}/multilingual`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.multilingualSubject.next({
                            type: 'MULTILINGUAL_DATA_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log(response);
                }),
                catchError((error) => {
                    this.multilingualSubject.next({
                        type: 'MULTILINGUAL_DATA_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getSourceTypes() {
        const url = `${environment.apiUrlDomain}/multilingual/sources`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.multilingualSubject.next({
                            type: 'MULTILINGUAL_SOURCE_TYPE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log(response);
                }),
                catchError((error) => {
                    this.multilingualSubject.next({
                        type: 'MULTILINGUAL_SOURCE_TYPE_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getLanguageTypes() {
        const url = `${environment.apiUrlDomain}/languages?projection=name%20id`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.multilingualSubject.next({
                            type: 'MULTILINGUAL_LANGUAGE_TYPE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log(response);
                }),
                catchError((error) => {
                    this.multilingualSubject.next({
                        type: 'MULTILINGUAL_LANGUAGE_TYPE_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    searchMultilingualData(searchKey: string = '', source: string = '', languageId: string = '') {
        const url = `${environment.apiUrlDomain}/multilingual?refId=${searchKey}&source=${source}&languageId=${languageId}`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.multilingualSubject.next({
                            type: 'MULTILINGUAL_SEARCH_DATA_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log(response);
                }),
                catchError((error) => {
                    this.multilingualSubject.next({
                        type: 'MULTILINGUAL_SEARCH_DATA_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    searchMultilingualCreateData(searchKey: string = '', source: string = '', pageNo: any = '') {
        let url = ``;
        switch (source) {
            case 'category':
                url = `${environment.apiUrlDomain}/categories?pageNo=${pageNo}`;
                break;
            case 'metadata':
                url = `${environment.apiUrlDomain}/metadata?pageNo=${pageNo}`;
                break;
            case 'screen':
                url = `${environment.apiUrlDomain}/screens?pageNo=${pageNo}`;
                break;
            case 'channel':
                url = `${environment.apiUrlDomain}/channels?pageNo=${pageNo}`;
                break;
            case 'genre':
                url = `${environment.apiUrlDomain}/genres?pageNo=${pageNo}`;
                break;
            case 'broadcaster':
                url = `${environment.apiUrlDomain}/broadcasters?pageNo=${pageNo}`;
                break;
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.multilingualSubject.next({
                            type: 'MULTILINGUAL_SEARCH_CREATE_DATA_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log(response);
                }),
                catchError((error) => {
                    this.multilingualSubject.next({
                        type: 'MULTILINGUAL_SEARCH_CREATE_DATA_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getDataOnAdd(id: any) {
        const url = `${environment.apiUrlDomain}/multilingual/${id}`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.multilingualSubject.next({
                            type: 'MULTILINGUAL_GET_ADD_DATA_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log(response);
                }),
                catchError((error) => {
                    this.multilingualSubject.next({
                        type: 'MULTILINGUAL_GET_ADD_DATA_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    createEditMultilingual(data: any) {
        const url = `${environment.apiUrlDomain}/multilingual`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .postRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    if (response.status == 201) {
                        this.multilingualSubject.next({
                            type: 'MULTILINGUAL_CREATE_EDIT_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log(response);
                }),
                catchError((error) => {
                    this.multilingualSubject.next({
                        type: 'MULTILINGUAL_CREATE_EDIT_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }
}
