import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-data-modal',
    templateUrl: './data-modal.component.html',
    styleUrls: ['./data-modal.component.scss'],
})
export class DataModalComponent {
    @Input() data: any;
    displayDataObject: any;
    displayData: string = '';

    ngOnInit() {
        // JSON.stringify(this.data, null, '\t');
        this.displayDataObject = JSON.parse(JSON.stringify(this.data));

        if (this.displayDataObject.pageType == 'deeplink') {
            this.displayData = this.displayDataObject.data;
        } else if (this.displayDataObject.pageType == 'dashboard') {
            this.displayData = JSON.stringify(this.displayDataObject.data, null, 4);
        }
        // this.data.data = JSON.stringify(this.data.data, null, 4);
    }
}
