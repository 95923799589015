<div class="upload text-body-s">
    <span class="uploadLabel">{{ labelText }}</span>
    <button type="button" class="j-button" (click)="fileinput.click()">
        <span class="text-button">Browse</span>
        <input type="file" style="display: none" #fileinput (change)="upload(fileinput?.files?.[0])" accept="image/*" />
    </button>
</div>
<div class="upload-preview" *ngIf="imgSrc != null && imgSrc != undefined && imgSrc != ''">
    <img [src]="imgSrc" />
    <span class="uploadfileLabel text-list-title">{{ filename | truncateFileName }}</span>
</div>

<!-- <button type="button" (click)="fileInput.click()">
    <span>Upload</span>
    <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
</button> -->
