import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SpinnerComponent } from './spinner.component';

@Injectable({
    providedIn: 'root',
})
export class SpinnerService {
    isTable: boolean = false;

    private subject = new Subject();

    spinnerObservable$: Observable<any> = this.subject.asObservable();

    /**
     * This function pushes the spinner component object into the spinnerObservable$ subject for dynamically loading the spinner component
     */
    loadSpinner() {
        const spinnerObj = { component: SpinnerComponent };
        this.subject.next(spinnerObj);
    }

    /**
     * This function pushes an object into the spinnerObservable$ subject for destroying the spinner component
     */
    destroySpinner() {
        const spinnerObj = { state: false };
        this.subject.next(spinnerObj);
    }
}
