import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { Subject, Observable } from 'rxjs';
import { tap, catchError, shareReplay } from 'rxjs/operators';
import { AppConstants } from 'src/app/constants/app-constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TrailerEditService {
    private authData: any;
    private trailerSubject = new Subject();
    traileredit$: Observable<any> = this.trailerSubject.asObservable();

    constructor(private auth: AuthService, private http: HttpService) {
        this.authData = this.auth.getAuthData();
    }

    getTrailerData(id: any = '') {
        const url = `${environment.apiUrlDomain}/metadata?id=${id}&projection=contentId%20name%20isSeason%20toShowSeason%20trailer%20seasonTrailer`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.trailerSubject.next({
                            type: 'EDIT_TRAILER_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log('METADATA GET', response);
                }),
                catchError((error) => {
                    this.trailerSubject.next({
                        type: 'EDIT_TRAILER_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    deleteTrailer(id: string, trailerType: any) {
        const url = `${environment.apiUrlDomain}/metadata/${id}/trailer/remove`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        const data: any = {
            trailerType: String(trailerType),
        };

        this.http
            .putRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    console.log('delete trailerSubject Data:', response);
                    this.trailerSubject.next({
                        type: 'TRAILER_DELETE_SUCCESSFUL',
                        data: response.body,
                    });
                }),
                catchError((error: any) => {
                    this.trailerSubject.next({
                        type: 'TRAILER_DELETE_FAILED',
                        data: error,
                    });
                    throw Error;
                })
            )
            .subscribe();
    }

    updateTrailer(id: string, trailerType: any, metadataId: any = '') {
        const url = `${environment.apiUrlDomain}/metadata/${metadataId}/trailer/add`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        const data: any = {
            trailerType: trailerType,
            trailerId: id,
        };

        this.http
            .putRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    console.log('trailer updated', response);
                    if (response.status == 200) {
                        this.trailerSubject.next({
                            type: 'TRAILER_UPDATE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log('TRAILER_ID_POST_SUCCESSFUL', response.body);
                }),
                catchError((error: any) => {
                    console.log('GHDHIDJSK', data, error);
                    throw Error;
                })
            )
            .subscribe();
    }

    getDataBySearch(contentType: any = '', pageNo: any = '', search: any = '') {
        const url = `${environment.apiUrlDomain}/metadata?any=${search}&contentType=${contentType}&pageNo=${pageNo}`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.trailerSubject.next({
                            type: 'TRAILER_SEARCH_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log('TRAILER_SEARCH_GET_SUCCESSFUL', response);
                }),
                catchError((error) => {
                    this.trailerSubject.next({
                        type: 'TRAILER_SEARCH_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }
}
