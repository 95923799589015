import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient, @Optional() @Inject('PORT') private port: any) {}

    postRequest(url: string, headers: any, data: any, fullResponse: boolean = true) {
        if (fullResponse) {
            return this.http.post(url, data, {
                headers: new HttpHeaders(headers),
                observe: 'response',
            });
        } else {
            return this.http.post(url, data, {
                headers: new HttpHeaders(headers),
            });
        }
    }

    getRequest(url: string, headers: any, fullResponse: boolean = true) {
        if (fullResponse) {
            return this.http.get(url, {
                headers: new HttpHeaders(headers),
                observe: 'response',
            });
        } else {
            return this.http.get(url, {
                headers: new HttpHeaders(headers),
            });
        }
    }

    getBlobRequest(url: string, headers: any) {
        return this.http.get(url, {
            headers: new HttpHeaders(headers),
            observe: 'response',
            responseType: 'blob',
        });
        // if (fullResponse) {
        //     return this.http.get(url, {
        //         headers: new HttpHeaders(headers),
        //         observe: 'response',
        //         responseType: 'blob'
        //     });
        // } else {
        //     return this.http.get(url, {
        //         headers: new HttpHeaders(headers),
        //         responseType: 'blob'
        //     });
        // }
    }

    fetchCaptcha(url: any, headers: any) {
        // const apiUrl = 'https://cms-dev.jiomedia.net/api/v1/captcha';

        // Make a GET request to fetch the captcha image
        return this.http.get(url, {
            headers: new HttpHeaders(headers),
            observe: 'response',
            responseType: 'arraybuffer',
        });
        // .subscribe(
        //     (response: any) => {
        //         // Set the captchaImage directly since it's already in base64 format
        //         // const captchaImage = 'data:image/png;base64,' + btoa(encodeURI(response));
        //         // console.log(captchaImage, 'captchImage');
        //         // return captchaImage;
        //     },
        //     (error) => {
        //         console.error('Error fetching captcha:', error);
        //     }
        // );
    }

    patchRequest(url: string, headers: any, data: any, fullResponse: boolean = true) {
        if (fullResponse) {
            return this.http.patch(url, data, {
                headers: new HttpHeaders(headers),
                observe: 'response',
            });
        } else {
            return this.http.patch(url, data, {
                headers: new HttpHeaders(headers),
            });
        }
    }

    deleteRequest(url: string, headers: any, fullResponse: boolean = true, data: any = '') {
        let options: any = {
            headers: new HttpHeaders(headers),
        };
        if (data != '') {
            options.body = data;
        }

        if (fullResponse) {
            options.observe = 'response';
        }

        return this.http.delete(url, options);
    }

    putRequest(url: string, headers: any, data: any, fullResponse: boolean = true) {
        if (fullResponse) {
            return this.http.put(url, data, {
                headers: new HttpHeaders(headers),
                observe: 'response',
            });
        } else {
            return this.http.put(url, data, {
                headers: new HttpHeaders(headers),
            });
        }
    }
}
