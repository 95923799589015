<div class="epgdata-page__header">
    <span class="text-heading-m">Trailers</span>
</div>

<div class="epgdata-page__search-filter-section">
    <!-- <div class="metadata-page__dropdown" style="--dropdown-width: 300px">
        <app-dropdown [data]="contentTypeDropdown"></app-dropdown>
    </div> -->
    <div class="epgdata-page__search-filter-section-row">
        <div class="epgdata-page__dropdown" style="--dropdown-width: 300px">
            <app-dropdown [data]="contentTypeDropdown" (selectedItemEvent)="selectContentType($event)"></app-dropdown>
        </div>
        <div class="epgdata-page__epgdata-searchbox" style="width: 300px">
            <app-search placeholderText="Search" (searched)="onSearched($event)"></app-search>
        </div>
        <div style="padding-left: var(--spacing-m)">
            <button class="j-button j-button--type-1" style="width: var(--spacing-m)" (click)="searchFilter()">
                <span class="text-button">Search</span>
            </button>
        </div>
    </div>
</div>
<div class="epgdata-page__body" style="margin-top: var(--spacing-s)">
    <div class="epgdata-page__table" *ngIf="trailerModalData.length != 0">
        <app-table tableName="TRAILER_EDIT_MODAL" [tableData]="trailerModalData"></app-table>
    </div>
    <app-pagination
        *ngIf="totalCount > 0"
        [pageNo]="pageNo"
        [pageCount]="pageCount"
        (goTo)="onGoTo($event)"
        (next)="onNext($event)"
        (previous)="onPrevious($event)"
    ></app-pagination>
    <div *ngIf="flag" class="update">
        <button class="j-button j-button--type-1" style="width: var(--spacing-m)" (click)="update()">
            <span class="text-button">Update</span>
        </button>
    </div>
</div>
