import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { CardsComponent } from './cards.component';

@NgModule({
    declarations: [CardsComponent],
    imports: [CommonModule, IconModule],
    exports: [CardsComponent],
})
export class CardsModule {}
