import { NgModule, APP_INITIALIZER } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastModule } from './components/toast/toast.module';
import { SpinnerModule } from './components/spinner/spinner.module';
import { HttpClientModule } from '@angular/common/http';
import { SearchModule } from './components/search/search.module';
import { ModalModule } from './components/modal/modal.module';
import { AppInitService } from './services/app-init.service';

export function initApp(appInitService: AppInitService): any {
    return () => appInitService.initApp();
}

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, AppRoutingModule, SpinnerModule, ToastModule, HttpClientModule, SearchModule, ModalModule],
    providers: [
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AppInitService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
