<div>
    <app-icon
        *ngIf="subType"
        ic="IcEditPen"
        size="s"
        color="var(--color-primary-60)"
        (click)="editBtnAction()"
    ></app-icon>
    <app-icon *ngIf="subType" ic="IcList" size="s" color="var(--color-primary-60)" (click)="listBtnAction()"></app-icon>
    <span class="icon-span" (click)="visibleModal()">
        <app-icon *ngIf="isItem" ic="IcVisible" size="s" color="var(--color-primary-60)"></app-icon>
    </span>
    <app-icon ic="IcTrash" size="s" color="var(--color-primary-60)" (click)="deleteBtnAction()"></app-icon>
</div>
