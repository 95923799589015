import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class DashboardPageGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(): boolean {
        var authdata = undefined;
        authdata = this.authService.getAuthData();
        if (authdata) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
