import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    @Input() pageNo: number = 0;
    @Input() pageCount: number = 0;

    @Output() goTo: EventEmitter<number> = new EventEmitter<number>();
    @Output() next: EventEmitter<number> = new EventEmitter<number>();
    @Output() previous: EventEmitter<number> = new EventEmitter<number>();

    public pages: number[] = [];
    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes['pageNo'] && changes['pageNo'].currentValue) ||
            (changes['pageCount'] && changes['pageCount'].currentValue)
        ) {
            this.pages = this.getPages(this.pageNo, this.pageCount);
        }
    }

    onGoTo(page: number) {
        this.goTo.emit(page);
    }
    onNext() {
        this.next.emit(this.pageNo);
    }

    onPrevious() {
        this.previous.next(this.pageNo);
    }

    getPages(pageNo: number, pageCount: number): number[] {
        if (pageCount <= 7) {
            return [...Array(pageCount).keys()].map((x) => ++x);
        }
        if (pageNo > 5) {
            if (pageNo >= pageCount - 4) {
                return [1, -1, pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
            } else {
                return [1, -1, pageNo - 1, pageNo, pageNo + 1, -1, pageCount];
            }
        }

        return [1, 2, 3, 4, 5, -1, pageCount];
    }
}
