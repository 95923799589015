import { Component, Input } from '@angular/core';
import { ModalService } from '../modal.service';
import { AppConstants } from 'src/app/constants/app-constants';
import { CategoryService } from 'src/app/pages/category/category.service';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'app-category-carousal-modal',
  templateUrl: './category-carousal-modal.component.html',
  styleUrls: ['./category-carousal-modal.component.scss']
})
export class CategoryCarousalModalComponent {
  @Input() data: any;
  modalData: any = [];
  header: any;
  @Input() showSpinner: boolean = true;

  categorySubscription$?: Subscription;

  dataFetched: boolean = false;

  constructor(
    private categoryService: CategoryService,
    private spinner: SpinnerService
  ) { }

  ngOnInit() {
    this.categorySubscription$ = this.categoryService.category$.subscribe((params: any) => {
      if (params) {
        switch (params.type) {
          case 'CATEGORY_CONTENT_DATA_GET_SUCCESSFUL':
            setTimeout(() => {
              this.showSpinner = false;
            }, 500);
            const categoryContentData = params.data;
            this.header = categoryContentData;
            this.modalData = categoryContentData.items.map((item: any) => {
              return {
                image: item.image ? item.image : '',
                albumArt: item.albumArt ? item.albumArt : '',
                bannerArt: item.bannerArt ? item.bannerArt : '',
                name: item.name,
                source: item.source,
                contentType: item.contentType,
              }
            });
            this.dataFetched = true;
            console.log(this.modalData);
            break;
        }
      }
    });

    this.categoryService.getCategoryContentData(this.data.id)

  }

  ngOnDestroy() {
    this.categorySubscription$?.unsubscribe();
  }
}

