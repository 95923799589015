import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ta-type-dropdown',
    templateUrl: './ta-type-dropdown.component.html',
    styleUrls: ['./ta-type-dropdown.component.scss'],
})
export class TaTypeDropdownComponent {
    @Input() callbackData: any;
    defaultIndex: any;

    ngOnInit() {
        console.log(this.callbackData);
        this.defaultIndex = this.callbackData[0].arg[1].options.findIndex((data: any) => {
            console.log(data.value);

            return data.value == this.callbackData[0].arg[0];
            // console.log(this.callbackData[0].arg[0]);
        });
        console.log(this.defaultIndex);
    }

    selectedItem(event: any) {
        console.log(this.callbackData);
        console.log(event);

        if (this.callbackData) {
            this.callbackData[0].callback(event.data, this.callbackData[0].arg[2], this.callbackData[0].arg[3]);
        }
    }
}
