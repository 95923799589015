import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ta-type-checkbox',
    templateUrl: './ta-type-checkbox.component.html',
    styleUrls: ['./ta-type-checkbox.component.scss'],
})
export class TaTypeCheckboxComponent {
    @Input() callbackData: any;

    checkboxChanged(event: any) {
        console.log(this.callbackData);

        if (this.callbackData) {
            this.callbackData[0].callback(
                this.callbackData[0].arg[0],
                event.checked,
                this.callbackData[0].arg[2],
                this.callbackData[0].arg[3]
            );
        }
    }
}
