import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastComponent } from './toast.component';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private subject = new Subject();
    toastObservable$: Observable<any> = this.subject.asObservable();

    constructor() {}

    /**
     * This function pushes the toast component into the toastObservable$ subject for dynamically loading the toast component
     * @param {string} message - The message to show in the toast component ( success | error )
     */
    showToast(message: string = '', messageType: string = 'success') {
        const toastObj = {
            component: ToastComponent,
            toastMessage: message,
            toastMessageType: messageType,
        };

        this.subject.next(toastObj);
    }
}
