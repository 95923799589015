<div style="position: relative" (click)="onClick()">
    <app-icon
        ic="IcList"
        size="s"
        color="var(--color-primary-60)"
        (click)="listBtnAction()"
        style="margin-right: 2px"
    ></app-icon>
    <app-checkbox
        [value]="callbackData[1].arg[0]"
        [checked]="callbackData[2].arg"
        (checkboxChange)="checkboxChanged($event)"
        [disabled]="callbackData[1].arg[3]"
    ></app-checkbox>
    <span class="tooltip" [ngClass]="showTooltip ? '' : 'tooltip--hidden'">
        There are no items in this category. This category cannot be scheduled. Please add items to this category from
        the category list page.
    </span>
</div>
