<div class="table-container">
    <table class="j-table" [ngClass]="tableName == 'PARENT_CHILD_SEARCH_MAPPING_TABLE' ? 'class-overflow' : ''">
        <thead>
            <tr>
                <th *ngFor="let h of tableHeader" class="text-heading-xxs">{{ h }}</th>
            </tr>
        </thead>

        <ng-container *ngIf="!rowDraggable">
            <tbody>
                <tr *ngFor="let row of tableData" class="text-body-xs">
                    <td *ngFor="let col of tableHeader" [ngSwitch]="tableComponentType[col]">
                        <ng-container *ngSwitchCase="'text'">{{ row[col] }}</ng-container>
                        <ng-container *ngSwitchCase="'checkbox'">
                            <app-checkbox></app-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'radio'">
                            <app-radio></app-radio>
                        </ng-container>
                        <ng-container *ngSwitchCase="'toggler'">
                            <app-toggler [callbackData]="row[col]"></app-toggler>
                        </ng-container>
                        <ng-container *ngSwitchCase="'IMAGE'">
                            <img [src]="row[col]" *ngIf="row[col] != 'NA'; else NA" style="width: 50px; height: 50px" />
                            <ng-template #NA>NA</ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'VISIBLE_ICON'">
                            <app-ta-type-d [isVisible]="row[col]"></app-ta-type-d>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CATEGORY_ACTION'">
                            <app-ta-type-a [callbackData]="row[col]"></app-ta-type-a>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SCREENS_ACTION'">
                            <app-ta-type-b [callbackData]="row[col]"></app-ta-type-b>
                        </ng-container>
                        <ng-container *ngSwitchCase="'PUBLISH_ACTION'">
                            <app-ta-type-c [callbackData]="row[col]"></app-ta-type-c>
                        </ng-container>
                        <ng-container *ngSwitchCase="'METADATA_ACTION'">
                            <app-ta-type-md [callbackData]="row[col]"></app-ta-type-md>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ID_ICON'">
                            <div style="display: flex; flex-direction: row; justify-content: center">
                                {{ row[col] | slice : 0 : 7 }}...
                                <app-ta-type-idcopy [data]="row[col]"></app-ta-type-idcopy>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CATEGORY_LIST_SEARCH_ACTION'">
                            <app-ta-type-checkbox [callbackData]="row[col]"></app-ta-type-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CATEGORY_LIST_DATA_ACTION'">
                            <app-ta-type-image-delete [callbackData]="row[col]"></app-ta-type-image-delete>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SCHEDULE_TABLE_ACTION'">
                            <app-ta-type-e [callbackData]="row[col]"></app-ta-type-e>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SCHEDULE_LIST_ACTION'">
                            <app-ta-type-delete [callbackData]="row[col]"></app-ta-type-delete>
                        </ng-container>
                        <ng-container *ngSwitchCase="'DASHBOARD_LIST_DATA'">
                            <app-ta-type-imagedata [data]="row[col]"></app-ta-type-imagedata>
                        </ng-container>
                        <ng-container *ngSwitchCase="'MULTILINGUAL_ACTION'">
                            <app-ta-type-add [callbackData]="row[col]"></app-ta-type-add>
                        </ng-container>
                        <ng-container *ngSwitchCase="'TRAILER_SEARCH_ACTION'">
                            <app-ta-type-radio [callbackData]="row[col]"></app-ta-type-radio>
                        </ng-container>
                        <ng-container *ngSwitchCase="'DROPDOWN_TYPE'">
                            <!-- <app-ta-type-radio [callbackData]="row[col]"></app-ta-type-radio> -->
                            <app-ta-type-dropdown [callbackData]="row[col]"></app-ta-type-dropdown>
                        </ng-container>
                        <ng-container *ngSwitchDefault>{{ row[col] }}</ng-container>
                    </td>
                </tr>
            </tbody>
        </ng-container>

        <ng-container *ngIf="rowDraggable">
            <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                <tr *ngFor="let row of tableData" class="text-body-xs" cdkDrag cdkDragLockAxis="y">
                    <td *ngFor="let col of tableHeader" [ngSwitch]="tableComponentType[col]">
                        <ng-container *ngSwitchCase="'text'">{{ row[col] }}</ng-container>
                        <ng-container *ngSwitchCase="'checkbox'">
                            <app-checkbox></app-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'radio'">
                            <app-radio></app-radio>
                        </ng-container>
                        <ng-container *ngSwitchCase="'toggler'">
                            <app-toggler [callbackData]="row[col]"></app-toggler>
                        </ng-container>
                        <ng-container *ngSwitchCase="'IMAGE'">
                            <img [src]="row[col]" *ngIf="row[col] != 'NA'; else NA" style="width: 50px; height: 50px" />
                            <ng-template #NA>NA</ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'VISIBLE_ICON'">
                            <app-ta-type-d [isVisible]="row[col]"></app-ta-type-d>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CATEGORY_ACTION'">
                            <app-ta-type-a [callbackData]="row[col]"></app-ta-type-a>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SCREENS_ACTION'">
                            <app-ta-type-b [callbackData]="row[col]"></app-ta-type-b>
                        </ng-container>
                        <ng-container *ngSwitchCase="'METADATA_ACTION'">
                            <app-ta-type-md [callbackData]="row[col]"></app-ta-type-md>
                        </ng-container>
                        <ng-container *ngSwitchCase="'PUBLISH_ACTION'">
                            <app-ta-type-c [callbackData]="row[col]"></app-ta-type-c>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ID_ICON'">
                            <div style="display: flex; flex-direction: row; justify-content: center">
                                {{ row[col] | slice : 0 : 7 }}...
                                <app-ta-type-idcopy [data]="row[col]"></app-ta-type-idcopy>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CATEGORY_LIST_SEARCH_ACTION'">
                            <app-ta-type-checkbox [callbackData]="row[col]"></app-ta-type-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CATEGORY_LIST_DATA_ACTION'">
                            <app-ta-type-image-delete [callbackData]="row[col]"></app-ta-type-image-delete>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SCHEDULE_TABLE_ACTION'">
                            <app-ta-type-e [callbackData]="row[col]"></app-ta-type-e>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SCHEDULE_LIST_ACTION'">
                            <app-ta-type-delete [callbackData]="row[col]"></app-ta-type-delete>
                        </ng-container>
                        <ng-container *ngSwitchCase="'DASHBOARD_LIST_DATA'">
                            <app-ta-type-imagedata [data]="row[col]"></app-ta-type-imagedata>
                        </ng-container>
                        <ng-container *ngSwitchCase="'MULTILINGUAL_ACTION'">
                            <app-ta-type-add [callbackData]="row[col]"></app-ta-type-add>
                        </ng-container>
                        <ng-container *ngSwitchCase="'TRAILER_SEARCH_ACTION'">
                            <app-ta-type-radio [callbackData]="row[col]"></app-ta-type-radio>
                        </ng-container>
                        <ng-container *ngSwitchCase="'DROPDOWN_TYPE'">
                            <!-- <app-ta-type-radio [callbackData]="row[col]"></app-ta-type-radio> -->
                            <div class="row row-cols-1">
                                <app-ta-type-dropdown [callbackData]="row[col]"></app-ta-type-dropdown>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>{{ row[col] }}</ng-container>
                    </td>
                </tr>
            </tbody>
        </ng-container>
    </table>
    <div class="table-spinner-container" *ngIf="showSpinner">
        <app-spinner [isTable]="true"></app-spinner>
    </div>
</div>
