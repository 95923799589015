import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { SearchComponent } from './search.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [SearchComponent],
    imports: [CommonModule, IconModule, ReactiveFormsModule],
    exports: [SearchComponent],
})
export class SearchModule {}
