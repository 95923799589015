import { Component, Input } from '@angular/core';
import { IconSizes, ICON_SIZE, ICON_SIZE_SVG } from './icon.model';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
    @Input() ic?: string;
    @Input() size: IconSizes = 'm';
    @Input() id!: string | null;
    @Input() color?: string;
    @Input() isBg: boolean = false;
    @Input() bgColor?: string;

    getStyles() {
        let iconSize = ICON_SIZE[this.size];
        let styleString;

        if (this.size) {
            styleString = `--icon-size: ${iconSize};`;
        }

        if (this.color) {
            styleString += `--icon-color: ${this.color};`;
        }

        if (this.isBg) {
            styleString += `--icon-bg: ${this.bgColor};`;
            styleString += `--icon-size-svg: ${ICON_SIZE_SVG[this.size]};`;
        }

        return styleString;
    }
}
