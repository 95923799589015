import { Component, Input } from '@angular/core';
import { DeleteConfirmModalComponent } from 'src/app/components/modal/delete-confirm-modal/delete-confirm-modal.component';
import { ModalService } from 'src/app/components/modal/modal.service';
import { TrailerEditModalComponent } from 'src/app/components/modal/trailer-edit-modal/trailer-edit-modal.component';

@Component({
    selector: 'app-ta-type-b',
    templateUrl: './ta-type-b.component.html',
    styleUrls: ['./ta-type-b.component.scss'],
})
export class TaTypeBComponent {
    @Input() callbackData: any;
    constructor(private modal: ModalService) {}

    editBtnAction() {
        console.log('CALLBACK RECEIVED', this.callbackData);
        if (this.callbackData[0].arg[2] == 'trailer') {
            console.log(
                '=======================================================================',
                this.callbackData[0].arg
            );

            if (this.callbackData) {
                this.modal.openModal({
                    component: TrailerEditModalComponent,
                    data: { id: this.callbackData[0].arg },
                    prefix: 'category-visible-table-data',
                });
                this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[3]);
            }
        } else {
            if (this.callbackData) {
                this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[1]);
            }
        }
    }

    deleteBtnAction() {
        console.log(this.callbackData);
        if (this.callbackData[0].arg[2] == 'trailer') {
            this.modal.openModal({
                component: DeleteConfirmModalComponent,
                data: {
                    callbackData: this.callbackData,
                    page: 'trailer',
                    text: 'Are you sure you want to delete this ?',
                },
                prefix: 'audit-delete-confirm',
            });
        } else if (this.callbackData[0].arg[2] == 'deeplink') {
            this.modal.openModal({
                component: DeleteConfirmModalComponent,
                data: {
                    callbackData: this.callbackData,
                    page: 'deeplink',
                    text: 'Are you sure you want to delete this ?',
                },
                prefix: 'audit-delete-confirm',
            });
        } else {
            if (this.callbackData) {
                this.modal.openModal({
                    component: DeleteConfirmModalComponent,
                    data: {
                        callbackData: this.callbackData,
                        page: 'screens',
                        text: 'Are you sure you want to delete this ?',
                    },
                    prefix: 'audit-delete-confirm',
                });
            }
        }
        // if (this.callbackData) {
        //     this.callbackData[1].callback(this.callbackData[1].arg[0], this.callbackData[1].arg[1]);
        // }
        // this.modal.openModal({
        //     component: DeleteConfirmModalComponent,
        //     data: { id: this.callbackData[1].arg[0], categoryComponent: this.callbackData[1].arg[1] },
        //     prefix: 'audit-delete-confirm'
        // });
    }
}
