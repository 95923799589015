<div class="modal-container">
    <div class="modal-container__dialog" [ngClass]="getModalDialogModifierClass(modalData.prefix)">
        <app-icon
            class="modal-container__close-btn"
            ic="IcClose"
            size="s"
            color="var(--color-primary-60)"
            (click)="closeModal()"
        ></app-icon>
        <div class="modal-container__dialog-body text-body-xs">
            <ng-template modalHost></ng-template>
        </div>
    </div>
    <div class="modal-backdrop" (click)="closeModal()"></div>
</div>
