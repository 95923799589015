<ng-container *ngIf="data.pageType == 'dashboard'">
    <div style="white-space: pre">
        {{ displayData }}
    </div>
</ng-container>

<ng-container *ngIf="data.pageType == 'deeplink'">
    <div>
        {{ displayData }}
    </div>
</ng-container>
