<div class="pagination">
    <ul class="pagination__container">
        <li class="pagination_list">
            <button class="paginationButton" [disabled]="pageNo === 1" (click)="onPrevious()">
                <app-icon class="arrow-btn" ic="IcChevronLeft" size="m"></app-icon> </button>
        </li>
        <li class="pagination_list text-body-s" *ngFor="let page of pages; index as i">
            <a class="pageNumber" *ngIf="page !== -1; else more" [class.current]="page === pageNo" tabindex="0"
                (click)="onGoTo(page)" (keyup.enter)="onGoTo(page)">{{
                page
                }}</a>
            <ng-template #more>
                <a class="more"> ... </a>
            </ng-template>
        </li>
        <li class="pagination_list">
            <button class="paginationButton" [disabled]="pageNo === pageCount" (click)="onNext()">
                <app-icon class="arrow-btn" ic="IcChevronRight" size="m"></app-icon>
            </button>
        </li>
    </ul>
</div>