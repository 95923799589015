import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { Subject, Observable } from 'rxjs';
import { tap, catchError, shareReplay, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { AppConstants } from 'src/app/constants/app-constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    private authData: any;
    private categorySubject = new Subject();

    private categorySubTypes: any = {};

    category$: Observable<any> = this.categorySubject.asObservable();

    constructor(private auth: AuthService, private http: HttpService) {
        this.authData = this.auth.getAuthData();
    }

    /**
     * This function will get the category subtypes
     */
    getCategorySubTypes() {
        if (Object.keys(this.categorySubTypes).length > 0) {
            this.categorySubject.next({
                type: 'CATEGORY_SUBTYPE_SUCCESSFUL',
                data: this.categorySubTypes,
            });
        } else {
            const url = `${environment.apiUrlDomain}/categories/subtypes`;

            const headers = {
                'Content-Type': 'application/json',
                'x-access-token': this.authData.token,
            };

            this.http
                .getRequest(url, headers)
                .pipe(
                    tap((response: any) => {
                        if (response.status == 200) {
                            this.categorySubTypes = response.body;
                            this.categorySubject.next({
                                type: 'CATEGORY_SUBTYPE_SUCCESSFUL',
                                data: this.categorySubTypes,
                            });
                        }
                        console.log(response);
                    }),
                    catchError((error) => {
                        this.categorySubject.next({
                            type: 'CATEGORY_SUBTYPE_FAILED',
                            data: error,
                        });
                        throw error;
                    }),
                    shareReplay()
                )
                .subscribe();
        }
    }

    /**
     * This function will get the category types
     */
    getCategoryTypes() {
        const url = `${environment.apiUrlDomain}/categories/types`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_TYPE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_TYPE_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getBrowseImage(img: string = '', label: string, uploadedFile: any) {
        const url = `${environment.apiUrlDomain}/images/upload?collection=category&imageType=thumbnail&id=1234`;

        const headers = {
            //'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };
        let formData = new FormData();
        formData.append('file', uploadedFile, uploadedFile.name);

        this.http
            .postRequest(url, headers, formData)
            .pipe(
                tap((response: any) => {
                    if (response.status == 201) {
                        this.categorySubject.next({
                            type: 'BROWSE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    console.log('Response for get browse image', response);
                })
            )
            .subscribe();
    }

    /**
     * This function will call the create category API to create a new category
     * @param {string} name
     * @param {string} description
     * @param {string} categoryType
     * @param {string} categorySubType
     * @param {boolean} visible
     * @param {string} thumbnail
     * @param {string} bgImage
     * @param {string} itemOrientation
     */
    createCategory(
        name: string,
        description: string,
        categoryType: string,
        categorySubType: string,
        visible: boolean = false,
        albumArt: any,
        bannerArt: any,
        squareArt: any,
        itemOrientation: string,
        languageId: string,
        programType: any,
        genreType: string,
        userLanguagePersonalization: boolean = false,
        isDynamic: boolean = false,
        autoUpdateItem: boolean = false,
        provider: string,
        businessType: string
        // videoLink: string
    ) {
        const url = `${environment.apiUrlDomain}/categories`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        const data: any = {
            // name: name,
            // description: description,
            // type: categoryType,
            // subType: categorySubType,
            // visible: visible,
            // albumArt: albumArt,
            // bannerArt: bannerArt,
            // squareArt: squareArt,
            // itemOrientation: itemOrientation,
            // languageId: languageId,
            // programType: programType,
            // genreId: genreType,
            // userLanguagePersonalization: userLanguagePersonalization,
            // isDynamic: isDynamic,
            // autoUpdateItem: autoUpdateItem,
            ...(name && { name }),
            ...(description && { description }),
            ...(categoryType && { type: categoryType }),
            ...(categorySubType && { subType: categorySubType }),
            ...(visible !== undefined && { visible }),
            ...(albumArt && { albumArt }),
            ...(bannerArt && { bannerArt }),
            ...(squareArt && { squareArt }),
            ...(itemOrientation && { itemOrientation }),
            ...(languageId && { languageId }),
            ...(programType && { programType }),
            ...(genreType && { genreId: genreType }),
            ...(userLanguagePersonalization !== undefined && { userLanguagePersonalization }),
            ...(isDynamic !== undefined && { isDynamic }),
            ...(autoUpdateItem !== undefined && { autoUpdateItem }),
            ...(provider && { provider }),
            ...(businessType && { businessType }),
        };

        console.log('my data is here', data);

        this.http
            .postRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    if (response.status == 201) {
                        this.categorySubject.next({
                            type: 'CATEGORY_CREATE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    // console.log(response);
                }),
                catchError((error: any) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_CREATE_FAILED',
                        data: error,
                    });
                    throw error;
                })
            )
            .subscribe();
    }

    getCategoryData(categoryType: string = 'Banner', pageNo: any = '') {
        let url = '';

        if (categoryType.length > 0) {
            url = `${environment.apiUrlDomain}/categories?type=${categoryType}&pageNo=${pageNo}&visible=true`;
        } else {
            url = `${environment.apiUrlDomain}/categories?pageNo=${pageNo}`;
        }
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getLanguageTypes() {
        const url = `${environment.apiUrlDomain}/languages?projection=name%20id&pageSize=100`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'LANGUAGE_DROPDOWN_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    // console.log(response);
                }),
                catchError((error: any) => {
                    this.categorySubject.next({
                        type: 'LANGUAGE_DROPDOWN_GET_FAILED',
                        data: error,
                    });
                    throw error;
                })
            )
            .subscribe();
    }

    getFastProvider() {
        const url = `${environment.apiUrlDomain}/service/master-config?key=fastPartnerOnboard`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'FAST_PROVIDER_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    // console.log(response);
                }),
                catchError((error: any) => {
                    this.categorySubject.next({
                        type: 'FAST_PROVIDER_GET_FAILED',
                        data: error,
                    });
                    throw error;
                })
            )
            .subscribe();
    }

    getProvider() {
        const url = `${environment.apiUrlDomain}/metadata/partnerOnboard/findAll?type=fast`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'PROVIDER_DROPDOWN_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    // console.log(response);
                }),
                catchError((error: any) => {
                    this.categorySubject.next({
                        type: 'PROVIDER_DROPDOWN_GET_FAILED',
                        data: error,
                    });
                    throw error;
                })
            )
            .subscribe();
    }

    getCategoryBySearch(
        categoryType: string = '',
        searchKey: string = '',
        categorySubType: string = '',
        visibleType: string = '',
        pageNo: any = ''
    ) {
        let url = `${environment.apiUrlDomain}/categories?type=${categoryType}&pageNo=${pageNo}`;
        if (searchKey != '') {
            url = url + `&q=${searchKey}`;
            console.log(url);
        }
        if (visibleType != '') {
            url = url + `&visible=${visibleType}`;
            console.log(url);
        }
        if (categorySubType != '') {
            url = url + `&subType=${categorySubType}`;
            console.log(url);
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getDataById(id: string) {
        let url = '';

        if (id.length > 0) {
            url = `${environment.apiUrlDomain}/categories/${id}`;
        } else {
            url = `${environment.apiUrlDomain}/categories`;
        }
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    console.log('get data by id response', response);
                    if (response.status == 200) {
                        console.log('get by id', response.body);
                        this.categorySubject.next({
                            type: 'DATA_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'DATA_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    /**
     * This function will call the create category API to create a new category
     * @param {string} name
     * @param {string} categoryType
     * @param {string} categorySubType
     * @param {boolean} visible
     * @param {string} thumbnail
     * @param {string} bgImage
     * @param {string} itemOrientation
     */
    editCategory(
        id: string,
        name: string,
        description: string = '',
        categoryType: string,
        categorySubType: string,
        visible: boolean,
        albumArt: any,
        bannerArt: any,
        squareArt: any,
        itemOrientation: string,
        languageId: string,
        userLanguagePersonalization: string,
        isDynamic: string,
        programType: any,
        genreType: any,
        autoUpdateItem: boolean,
        provider: string,
        businessType: any
    ) {
        const url = `${environment.apiUrlDomain}/categories/${id}`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        const data: any = {
            // name: name,
            // type: categoryType,
            // subType: categorySubType,
            // visible: visible,
            // description: description,
            // albumArt: albumArt,
            // bannerArt: bannerArt,
            // squareArt: squareArt,
            // itemOrientation: itemOrientation,
            // languageId: languageId,
            // userLanguagePersonalization: userLanguagePersonalization,
            // isDynamic: isDynamic,
            // programType: programType,
            // genreId: genreType,
            // autoUpdateItem: autoUpdateItem,
            ...(name && { name }),
            ...(categoryType && { type: categoryType }),
            ...(categorySubType && { subType: categorySubType }),
            ...(visible !== undefined && { visible }),
            // ...(description && { description }),
            description,
            ...(albumArt && { albumArt }),
            ...(bannerArt && { bannerArt }),
            ...(squareArt && { squareArt }),
            ...(itemOrientation && { itemOrientation }),
            // ...(languageId && { languageId }),
            languageId,
            ...(userLanguagePersonalization !== undefined && { userLanguagePersonalization }),
            ...(isDynamic !== undefined && { isDynamic }),
            ...(programType && { programType }),
            // ...(genreType && { genreId: genreType }),
            genreId: genreType,
            ...(autoUpdateItem !== undefined && { autoUpdateItem }),
            ...(provider && { provider }),
            ...(businessType && { businessType }),
        };

        this.http
            .putRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_UPDATE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    // console.log(response);
                }),
                catchError((error: any) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_UPDATE_FAILED',
                        data: error,
                    });
                    throw error;
                })
            )
            .subscribe();
    }

    getCategoryDataBySubType(categoryType: string, categorySubType: string) {
        let url = '';

        if (categoryType.length > 0 && categorySubType.length > 0) {
            url = `${environment.apiUrlDomain}/categories?type=${categoryType}&subType=${categorySubType}`;
        } else if (categoryType.length > 0) {
            url = `${environment.apiUrlDomain}/categories?type=${categoryType}`;
        } else {
            url = `${environment.apiUrlDomain}/categories`;
        }
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_BY_SUBTYPE_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_BY_SUBTYPE_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    /**
     * This will call the content data API
     * @param {string} id - content id
     */
    getCategoryContentData(id: string, pageNo: any = '') {
        let url = `${environment.apiUrlDomain}/categories/${id}/contents`;

        const queryParams = [];

        if (pageNo) {
            queryParams.push(`pageNo=${pageNo}`);
        }

        if (queryParams.length > 0) {
            url += `&${queryParams.join('&')}`;
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_CONTENT_DATA_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_CONTENT_DATA_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getCategoryCollectionData(id: string) {
        let url = `${environment.apiUrlDomain}/categories/${id}/category`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_COLLECTION_DATA_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_COLLECTION_DATA_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getCategoryListData(id: string) {
        let url = `${environment.apiUrlDomain}/categories?id=${id}&projection=name%20type%20subType%20itemOrientation%20businessType%20provider%20genreId`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_LIST_DATA_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_LIST_DATA_GET_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    /**
     * This function will call the search api for the category list page based on filters
     * @param {string} searchKey -
     * @param {string} contentType -
     * @param {string} langauge -
     * @param {string} provider -
     */
    getCategoryListDataSearch(
        businessType: string = '',
        contentType: string = '',
        languageId: string = '',
        genres: any = '',
        provider: string = '',
        searchKey: string = '',
        status: any = '',
        isAvailableForFast: any = '',
        pageNo: any = ''
    ) {
        let url = `${environment.apiUrlDomain}/metadata`;

        const queryParams = [];
        if (businessType) {
            queryParams.push(`businessType=${businessType}`);
        }
        if (searchKey) {
            queryParams.push(`any=${searchKey}`);
        }
        if (languageId) {
            queryParams.push(`languageId=${languageId}`);
        }
        if (genres) {
            queryParams.push(`genres=${genres}`);
        }
        if (contentType) {
            queryParams.push(`contentType=${contentType}`);
        }
        if (status) {
            queryParams.push(`status=${status}`);
        }
        if (provider) {
            queryParams.push(`provider=${provider}`);
        }
        if (isAvailableForFast) {
            queryParams.push(`isAvailableForFast=${isAvailableForFast}`);
        }
        if (pageNo) {
            queryParams.push(`pageNo=${pageNo}`);
        }

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_LIST_SEARCH_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_LIST_SEARCH_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getCategoryDataSearch(contentType: string = '', languageId: string = '', type: string = '', pageNo: any = '') {
        let url = `${environment.apiUrlDomain}/categories?status=1&type=Collection&applyOn=search`;

        const queryParams = [];

        if (pageNo) {
            queryParams.push(`pageNo=${pageNo}`);
        }

        if (queryParams.length > 0) {
            url += `&${queryParams.join('&')}`;
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_SEARCH_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_SEARCH_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getDeeplinkDataSearch(searchKey: string = '', type: string = '', pageNo: any = '') {
        let url = `${environment.apiUrlDomain}/deeplink?status=1`;

        if (searchKey != '') {
            url = url + `&any=${searchKey}`;
            console.log(url);
        }

        if (type != '') {
            url = url + `&type=${type}`;
            console.log(url);
        }

        if (pageNo != '') {
            url = url + `&pageNo=${pageNo}`;
            console.log(url);
        }

        // let url = `${environment.apiUrlDomain}/deeplink?name=deeplink&searchKey=${searchKey}&type=${type}`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_DEEPLINK_SEARCH_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_DEEPLINK_SEARCH_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    getChannelEpgList(searchKey: any, pageNo: any = '') {
        const url = `${environment.apiUrlDomain}/channels?status=1&pageNo=${pageNo}&projection=woiId%20id%20name%20image&any=${searchKey}`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CHANNEL_LIST_EPG_GET_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                    // console.log(response);
                }),
                catchError((error: any) => {
                    this.categorySubject.next({
                        type: 'CHANNEL_LIST_EPG_GET_FAILED',
                        data: error,
                    });
                    throw error;
                })
            )
            .subscribe();
    }

    /**
     * This function will update the contents in the content list of the category
     * @param {string} id - the category id which need to be updated
     * @param {string[]} items - an array of ids to be updated to the list
     */
    updateCategoryListData(id: string, items: Array<string>) {
        const url = `${environment.apiUrlDomain}/categories/${id}/contents`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        const data = {
            items: items,
        };

        this.http
            .postRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    if (response.status == 201) {
                        this.categorySubject.next({
                            type: 'CATEGORY_LIST_UPDATE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_LIST_UPDATE_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    /**
     * This api will call the api for table in schedule page
     * @param {string} searchKey - the searh string
     * @param {string} itemOrientation - the aspect ratio
     * @param {string} railType - the category type
     */
    getSchedulePageCategoryData(q: any = '', itemOrientation: string = '', railType: string = '', pageNo: any = '') {
        // const url = `${environment.apiUrlDomain}/categories?q=${q}&itemOrientation=${itemOrientation}&visible=true&type=${railType}&nonEmptyCategories=true&pageNo=${pageNo}`;
        let url = `${environment.apiUrlDomain}/categories`;
        const queryParams = [];

        if (q) {
            queryParams.push(`q=${q}`);
        }
        if (itemOrientation) {
            queryParams.push(`itemOrientation=${itemOrientation}`);
        }
        if (railType) {
            queryParams.push(`type=${railType}`);
        }
        if (pageNo) {
            queryParams.push(`pageNo=${pageNo}`);
        }

        queryParams.push(`visible=${true}`);
        queryParams.push(`nonEmptyCategories=${true}`);

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_SCHEDULE_DATA_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'CATEGORY_SCHEDULE_DATA_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    exportScreensData(type: string = '', subType: string = '', visible: string = '') {
        let url = `${environment.apiUrlDomain}/categories/export/csv`;

        const queryParams = [];
        if (type) {
            queryParams.push(`type=${type}`);
        }
        if (subType) {
            queryParams.push(`subType=${subType}`);
        }
        if (visible) {
            queryParams.push(`visible=${visible}`);
        }

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getBlobRequest(url, headers)
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'CATEGORY_EXPORT_CSV_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                })
            )
            .subscribe();
    }

    deleteCategoriesData(id: any) {
        const url = `${environment.apiUrlDomain}/categories/${id}`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .deleteRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    console.log('delete Screens Data:', response);
                    this.categorySubject.next({
                        type: 'CATEGORIES_DATA_DELETE_SUCCESSFUL',
                        data: response.body,
                    });
                }),
                catchError((error: any) => {
                    this.categorySubject.next({
                        type: 'CATEGORIES_DATA_DELETE_FAILED',
                        data: error,
                    });
                    throw Error;
                })
            )
            .subscribe();
    }

    getImageModalData(itemId: any, categoryId: any, source: any, type: any) {
        const url = `${
            environment.apiUrlDomain
        }/categories/item/image?categoryId=${categoryId}&itemId=${itemId}&source=${source}${
            type ? `&type=${type}` : ''
        }`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .getRequest(url, headers)
            .pipe(
                tap((response: any) => {
                    if (response.status == 200) {
                        this.categorySubject.next({
                            type: 'IMAGE_MODAL_DATA_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'IMAGE_MODAL_DATA_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    submitImageModalData(data: any) {
        const url = `${environment.apiUrlDomain}/categories/item/image`;

        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': this.authData.token,
        };

        this.http
            .postRequest(url, headers, data)
            .pipe(
                tap((response: any) => {
                    if (response.status == 201) {
                        this.categorySubject.next({
                            type: 'SUBMIT_IMAGE_MODAL_DATA_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.categorySubject.next({
                        type: 'SUBMIT_IMAGE_MODAL_DATA_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }
}
