import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppSettings } from './carousal.settings';

@Component({
    selector: 'app-carousal',
    templateUrl: './carousal.component.html',
    styleUrls: ['./carousal.component.scss'],
})
export class CarousalComponent {
    @Input() pageType!: string;
    @Input() carousalData: any;
    @Output() showLabelEvent = new EventEmitter<any>();
    @Output() removeLabelEvent = new EventEmitter<any>();
    @Output() showCardEvent = new EventEmitter<any>();
    @Output() removeCardEvent = new EventEmitter<any>();
    @Output() showCollectionCardEvent = new EventEmitter<any>();
    @Output() hideCollectionCardEvent = new EventEmitter<any>();

    iconCardData: any;

    iconLabelData: boolean = false;

    iconShowData: any;

    isCollection: boolean = false;

    settings: any;

    orientation: any;

    schedule: any;

    unpublishData: any;

    // typeOfCarousal: any;

    @Output() unpublishEvent = new EventEmitter<any>();

    ngOnInit() {
        console.log('full carousal data', this.carousalData);
        // this.typeOfCarousal = this.carousalData.category.type;
        // console.log(this.carousalData.category.type)
        switch (this.pageType) {
            case 'previewPage':
                this.orientation = this.carousalData.category?.itemOrientation;
                if (
                    this.carousalData?.category?.type == 'Collection' &&
                    this.carousalData?.category?.subType == 'Collection'
                ) {
                    this.isCollection = true;
                }
                break;
            case 'previewPageSubCarousal':
                console.log('previewPageSubCarousal', this.carousalData.orientation);
                this.orientation = this.carousalData?.orientation;
                break;
            case 'pdpPage':
                console.log('Hi', this.carousalData, this.carousalData?.category.itemOrientation);
                this.orientation = this.carousalData?.category.itemOrientation;
                break;
        }

        // if (this.typeOfCarousal == 'Banner') {
        //     this.settings = AppSettings.CAROUSAL_SETTINGS.carousal_banner;
        // }
        // else {
        switch (this.orientation) {
            case 'Landscape':
                console.log('In landscape');
                this.settings = AppSettings.CAROUSAL_SETTINGS.carousal_l;
                break;

            case 'Portrait':
                console.log('In Portrait');
                this.settings = AppSettings.CAROUSAL_SETTINGS.carousal_p;
                break;

            case 'Square':
                console.log('In Square');
                this.settings = AppSettings.CAROUSAL_SETTINGS.carousal_s;
                break;
            // default:
            //     this.settings = AppSettings.CAROUSAL_SETTINGS.carousal_l;
            //     break;
        }
        // }
    }

    ngOnChanges() {
        this.iconCardData = [];
        this.iconShowData = [];

        switch (this.pageType) {
            case 'previewPage':
                if (this.carousalData.items.length > 0) {
                    for (let j = 0; j < this.carousalData.items.length; j++) {
                        this.iconCardData.push(false);
                        this.iconShowData.push(false);
                    }
                }
                break;
            // case 'previewPageSubCarousal':
            //     if (this.carousalData.item.length > 0) {
            //         for (let j = 0; j < this.carousalData.items.length; j++) {

            //         }
            //     }
            //     console.log(this.iconShowData);
            //     break;
        }
    }

    getData(schedule: any) {
        console.log(schedule);
    }

    showLabelId(data: any) {
        this.iconLabelData = true;
        this.showLabelEvent.emit(data);
    }

    showCardId(labelId: any, data: any, j: any) {
        this.iconCardData[j] = !this.iconCardData[j];
        this.showCardEvent.emit({ labelId: labelId, item: data });
    }

    removeLabelId(data: any) {
        this.iconLabelData = false;
        this.removeLabelEvent.emit(data);
    }

    showCollectionCard(data: any, index: any) {
        this.iconShowData = [];

        switch (this.pageType) {
            case 'previewPage':
                if (this.carousalData.items.length > 0) {
                    for (let j = 0; j < this.carousalData.items.length; j++) {
                        this.iconShowData.push(false);
                    }
                }
                break;
        }
        this.iconShowData[index] = !this.iconShowData[index];
        // this.showCardEvent.emit({ labelId: labelId, item: data });
        console.log(this.iconShowData);

        this.showCollectionCardEvent.emit(data);
    }

    hideCollectionCard(data: any, index: any) {
        this.iconShowData[index] = !this.iconShowData[index];
        // this.removeLabelEvent.emit(data);
        console.log(this.iconShowData);

        this.hideCollectionCardEvent.emit(data);
    }

    removeCardId(labelId: any, data: any, j: any) {
        this.iconCardData[j] = !this.iconCardData[j];
        // if (!this.iconCardData.includes(true)) {
        //     this.iconLabelData = false;
        // }
        this.removeCardEvent.emit({ labelId: labelId, item: data });
    }
}
