<div *ngIf="modalData != ''">
    <div class="wrapper__header text-body-s" style="margin-left: var(--spacing-s); padding: var(--spacing-s)">
        <span> <b>Name:</b> {{ header.name }} | </span>
        <span> <b>Type:</b> {{ header.type }} | </span>
        <span> <b>Orientation:</b> {{ header.itemOrientation }} </span>
    </div>
    <div
        [ngClass]="header.itemOrientation == 'Landscape' ? 'row row-cols-3' : 'row row-cols-4'"
        style="--grid-gutter-width: var(--spacing-s); padding: var(--spacing-s)"
    >
        <div class="wrapper__card" *ngFor="let item of modalData">
            <div
                style="
                    box-shadow: var(--shadow-s);
                    background-color: #ffffff;
                    border-radius: var(--radius-medium);
                    margin: var(--spacing-xs);
                    padding: var(--spacing-xs);
                "
            >
                <div class="wrapper__card-container" *ngIf="item.source == 'Cpp'">
                    <div class="wrapper__card-landscape" *ngIf="header.itemOrientation == 'Landscape'">
                        <img class="image-landscape" src="{{ item.bannerArt }}" alt="bannerArt" />
                    </div>
                    <div class="wrapper__card-portrait" *ngIf="header.itemOrientation == 'Portrait'">
                        <img class="image-portrait" src="{{ item.albumArt }}" alt="albumArt" />
                    </div>
                    <div class="wrapper__card-square" *ngIf="header.itemOrientation == 'Square'">
                        <img class="image-square" src="{{ item.squareArt }}" alt="squareArt" />
                    </div>
                </div>
                <div class="wrapper__card-container" *ngIf="item.source == 'Epg' || item.source == 'Saavn'">
                    <div class="wrapper__card-landscape">
                        <img class="image-landscape" src="{{ item.image }}" alt="image" />
                    </div>
                </div>
                <div class="wrapper__card-container" *ngIf="item.source == 'Deeplink'">
                    <div class="wrapper__card-landscape">
                        <img
                            class="image-landscape"
                            src="{{ item.bannerArt || item.albumArt || item.squareArt }}"
                            alt="bannerArt"
                        />
                    </div>
                </div>
                <!-- <div class="wrapper__card-container" [ngSwitch]="header.itemOrientation">
                    <div class="wrapper__card-landscape" *ngSwitchCase="'Landscape'">
                        <img class="image-landscape" src="{{item.bannerArt}}" alt="bannerArt">
                    </div>
                    <div class="wrapper__card-portrait" *ngSwitchCase="'Portrait'">
                        <img class="image-portrait" src="{{item.albumArt}}" alt="albumArt">
                    </div>
                    <div class="wrapper__card-square" *ngSwitchCase="'Square'">
                        <img class="image-square" src="{{item.squareArt}}" alt="squareArt">
                    </div>
                    <div class="wrapper__card-landscape" *ngSwitchDefault>
                        <img class="image-landscape" src="{{item.image}}" alt="albumArt">
                    </div>
                </div> -->
                <div class="wrapper__card-text text-body-xs" style="padding: 10px; height: 70px">
                    <div class="ellipsis">Name: {{ item.name }}</div>
                    <div class="ellipsis">Source: {{ item.source }}</div>
                    <div *ngIf="item.source != 'Saavn'" class="ellipsis">Content Type: {{ item.contentType }}</div>
                </div>
            </div>
        </div>
        <div class="modal-spinner-container" *ngIf="showSpinner">
            <app-spinner [isTable]="true"></app-spinner>
        </div>
    </div>
</div>

<div class="no-data-found" *ngIf="modalData == '' && dataFetched">
    <div class="no-data-found__text text-heading-m">No Data Found</div>
</div>
