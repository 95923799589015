import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ta-type-d',
    templateUrl: './ta-type-d.component.html',
    styleUrls: ['./ta-type-d.component.scss'],
})
export class TaTypeDComponent {
    @Input() isVisible: boolean = false;

    ngOnInit() {}
}
