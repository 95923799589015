import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AppInitService {
    constructor() {}

    initApp(): any {
        const request = new XMLHttpRequest();
        request.open('GET', 'assets/config/environment.json', false); // `false` makes the request synchronous
        request.send(null);

        if (request.status === 200) {
            const env = JSON.parse(request.responseText);
            environment.env = env.env;
            environment.production = env.production;
            environment.apiUrlDomain = env.apiUrlDomain;

            environment.application = env.application;
            console.log(env, '---ENV received from API call---');
            console.log(environment, '---ENV---');
            return env;
        }
    }
}
