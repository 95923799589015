import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { SpinnerhostDirective } from 'src/app/directives/spinnerhost/spinnerhost.directive';

@NgModule({
    declarations: [SpinnerComponent, SpinnerhostDirective],
    imports: [CommonModule],
    exports: [SpinnerComponent, SpinnerhostDirective],
})
export class SpinnerModule {}
