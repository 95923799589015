import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
})
export class RadioComponent {
    @Input() radioBtnGroup = 'default';
    @Input() labelText: string = '';
    @Input() value: any;
    @Input() checked: any = false;
    @Input() disabled: any = false;
    @Output() radioChange = new EventEmitter<any>();

    ngOnInit() {}

    radioCheckedChanged(event: any) {
        console.log('RADIO', event);
        this.radioChange.emit({
            checked: event.target.checked,
            value: this.value,
            radioBtnGroup: this.radioBtnGroup,
        });
    }

    setRadioLock(event: any) {
        if (this.disabled) {
            event.preventDefault();
        }
    }
}
