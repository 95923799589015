import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { TogglerModule } from '../toggler/toggler.module';
import { RadioModule } from '../radio/radio.module';
import { TableActionsModule } from './actions/ta.modulet';
import { SpinnerModule } from '../spinner/spinner.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [TableComponent],
    imports: [
        CommonModule,
        CheckboxModule,
        TogglerModule,
        RadioModule,
        TableActionsModule,
        SpinnerModule,
        DragDropModule,
    ],
    exports: [TableComponent],
})
export class TableModule {}
