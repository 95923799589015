import { Component, Input } from '@angular/core';
import { AddRemoveDataComponent } from 'src/app/components/modal/add-remove-data/add-remove-data.component';
import { ModalService } from 'src/app/components/modal/modal.service';

@Component({
  selector: 'app-ta-type-add',
  templateUrl: './ta-type-add.component.html',
  styleUrls: ['./ta-type-add.component.scss']
})
export class TaTypeAddComponent {
  @Input() callbackData: any;
  constructor(private modal: ModalService) { }

  editBtnAction() {
    if (this.callbackData) {
      this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[1]);
    }
  }

  visibleModal() {
    if (this.callbackData) {
      this.modal.openModal({
        component: AddRemoveDataComponent,
        data: { data: this.callbackData[0].arg[0] },
        prefix: 'multilinguist-data',
      });
      console.log(this.callbackData);
    }
  }
}
