import { Component, Input } from '@angular/core';
import { ToastService } from 'src/app/components/toast/toast.service';

@Component({
    selector: 'app-ta-type-idcopy',
    templateUrl: './ta-type-idcopy.component.html',
    styleUrls: ['./ta-type-idcopy.component.scss'],
})
export class TaTypeIdcopyComponent {
    @Input() data: any;

    constructor(private toast: ToastService) {}

    ngOnChanges() {}

    copyBtnAction() {
        if (this.data) {
            const tempInput = document.createElement('input');
            tempInput.value = this.data;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            this.toast.showToast(`${this.data} copied to clipboard.`, 'success');
        }
    }
}
