<label class="checkbox-btn">
    <input 
    type="checkbox" 
    (change)="checkboxChanged($event)" 
    (click)="setCheckboxLock($event)" 
    [checked]="checked"
    [disabled]="disable"
     />
    <span class="tickmark"></span>
    <span class="checkbox-label text-body-s">{{ labelText }}</span>
</label>
