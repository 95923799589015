import { ToasthostDirective as ToastDirective } from './directives/toasthost/toasthost.directive';
import { Component, OnDestroy, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from './components/spinner/spinner.service';
import { SpinnerhostDirective } from './directives/spinnerhost/spinnerhost.directive';
import { ToastService } from './components/toast/toast.service';
import { ModalService } from './components/modal/modal.service';
import { ModalhostDirective } from './directives/modalhost/modalhost.directive';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private toastSubscription$: Subscription | undefined;
    @ViewChild(ToastDirective, { static: true }) toast!: ToastDirective;
    toastDisappearTimeout: any;
    toastDestroyTimeout: any;

    private spinnerSubscription$: Subscription | undefined;
    @ViewChild(SpinnerhostDirective, { static: true }) spinner!: SpinnerhostDirective;

    private modalSubscription$: Subscription | undefined;
    @ViewChild(ModalhostDirective, { static: true }) modal!: ModalhostDirective;

    modalData: any;
    isModalOpen: boolean = false;

    constructor(
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private modalService: ModalService,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    ngOnInit(): void {
        this.toastSubscription$ = this.toastService.toastObservable$.subscribe((params: any) => {
            if (params && params.toastMessage != '') {
                this.loadToastComponent(params);
            }
        });

        this.spinnerSubscription$ = this.spinnerService.spinnerObservable$.subscribe((params: any) => {
            if (params.state == false) {
                this.destroySpinnerComponent();
            } else {
                this.loadSpinnerComponent(params);
            }
        });

        this.modalSubscription$ = this.modalService.modalObservable$.subscribe((params: any) => {
            if (params.state == false) {
                this.isModalOpen = false;
                this.modalData = null;
            } else {
                this.isModalOpen = true;
                this.modalData = params;
            }
        });
    }

    /**
     * This function dynamically loads the toast component into the viewContainer
     * @param {object} toastComponentObject - toast.service.ts object which contains the component to dynamically load
     */
    loadToastComponent(toastComponentObject: any) {
        clearTimeout(this.toastDisappearTimeout);
        clearTimeout(this.toastDestroyTimeout);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(toastComponentObject.component);
        const viewContainerRef = this.toast.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent<any>(componentFactory);
        componentRef.instance.toastMessage = toastComponentObject.toastMessage;
        componentRef.instance.messageType = toastComponentObject.toastMessageType;

        this.toastDisappearTimeout = setTimeout(() => {
            componentRef.instance.appear = false;
        }, 3000);

        this.toastDestroyTimeout = setTimeout(() => {
            this.destroyToastComponent();
        }, 3500);
    }

    destroyToastComponent() {
        const viewContainerRef = this.toast.viewContainerRef ? this.toast.viewContainerRef : undefined;
        if (viewContainerRef) {
            viewContainerRef.clear();
            clearTimeout(this.toastDisappearTimeout);
            clearTimeout(this.toastDestroyTimeout);
        }
    }

    /**
     * This function dynamically loads the spinner component into the viewContainer
     * @param {object} spinnerComponentObject - spinner.service.ts object which conatins the component to dynamically load
     */
    loadSpinnerComponent(spinnerComponentObject: any) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            spinnerComponentObject.component
        );
        const viewContainerRef = this.spinner.viewContainerRef;
        viewContainerRef.clear();
        viewContainerRef.createComponent<any>(componentFactory);
    }

    destroySpinnerComponent() {
        const viewContainerRef = this.spinner.viewContainerRef ? this.spinner.viewContainerRef : undefined;
        if (viewContainerRef) {
            viewContainerRef.clear();
        }
    }

    ngOnDestroy(): void {
        this.toastSubscription$?.unsubscribe();
        this.spinnerSubscription$?.unsubscribe();
        this.modalSubscription$?.unsubscribe();
    }
}
