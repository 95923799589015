import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ta-type-delete',
    templateUrl: './ta-type-delete.component.html',
    styleUrls: ['./ta-type-delete.component.scss'],
})
export class TaTypeDeleteComponent {
    @Input() callbackData: any;

    deleteBtnAction() {
        if (this.callbackData) {
            this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[1]);
        }
    }
}
