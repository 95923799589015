import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TATypeAComponent } from './ta-type-a/ta-type-a.component';
import { IconModule } from '../../icon/icon.module';
import { RouterModule } from '@angular/router';
import { TaTypeBComponent } from './ta-type-b/ta-type-b.component';
import { TaTypeCComponent } from './ta-type-c/ta-type-c.component';
import { TaTypeDComponent } from './ta-type-d/ta-type-d.component';
import { TaTypeCheckboxComponent } from './ta-type-checkbox/ta-type-checkbox.component';
import { CheckboxModule } from '../../checkbox/checkbox.module';
import { TaTypeDeleteComponent } from './ta-type-delete/ta-type-delete.component';
import { TaTypeIdcopyComponent } from './ta-type-idcopy/ta-type-idcopy.component';
import { TaTypeImagedataComponent } from './ta-type-imagedata/ta-type-imagedata.component';
import { TaTypeEComponent } from './ta-type-e/ta-type-e.component';
import { TaTypeMdComponent } from './ta-type-md/ta-type-md.component';
import { TaTypeAddComponent } from './ta-type-add/ta-type-add.component';
import { TaTypeRadioComponent } from './ta-type-radio/ta-type-radio.component';
import { RadioModule } from '../../radio/radio.module';
import { TaTypeImageDeleteComponent } from './ta-type-image-delete/ta-type-image-delete.component';
import { TaTypeDropdownComponent } from './ta-type-dropdown/ta-type-dropdown.component';
import { DropdownModule } from '../../dropdown/dropdown.module';

@NgModule({
    declarations: [
        TATypeAComponent,
        TaTypeBComponent,
        TaTypeCComponent,
        TaTypeDComponent,
        TaTypeCheckboxComponent,
        TaTypeDeleteComponent,
        TaTypeIdcopyComponent,
        TaTypeEComponent,
        TaTypeImagedataComponent,
        TaTypeMdComponent,
        TaTypeAddComponent,
        TaTypeRadioComponent,
        TaTypeImageDeleteComponent,
        TaTypeDropdownComponent,
    ],
    imports: [CommonModule, IconModule, CheckboxModule, RouterModule, RadioModule, DropdownModule],
    exports: [
        TATypeAComponent,
        TaTypeBComponent,
        TaTypeCComponent,
        TaTypeDComponent,
        TaTypeCheckboxComponent,
        TaTypeDeleteComponent,
        TaTypeIdcopyComponent,
        TaTypeEComponent,
        TaTypeImagedataComponent,
        TaTypeMdComponent,
        TaTypeAddComponent,
        TaTypeRadioComponent,
        TaTypeImageDeleteComponent,
        TaTypeDropdownComponent,
    ],
})
export class TableActionsModule {}
