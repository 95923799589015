export const TableConfig: any = {
    SCREENS_LIST: {
        HEADER: ['ID', 'Name', 'Type', 'Status', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Type: 'text',
            Status: 'VISIBLE_ICON',
            Actions: 'SCREENS_ACTION',
        },
    },
    SCREENS_MANAGE: {
        HEADER: ['ID', 'Name', 'Status', 'Action'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Status: 'VISIBLE_ICON',
            Action: 'toggler',
        },
    },
    CATEGORY: {
        HEADER: ['ID', 'Name', 'Aspect Ratio', 'SubType', 'Visible', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            'Aspect Ratio': 'text',
            // Image: 'IMAGE',
            SubType: 'text',
            Visible: 'VISIBLE_ICON',
            Actions: 'CATEGORY_ACTION',
        },
    },
    PUBLISH: {
        HEADER: ['ID', 'Name', 'Schedule', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Schedule: 'text',
            Actions: 'PUBLISH_ACTION',
        },
    },
    CPP_LIST_SEARCH: {
        HEADER: [
            'ID',
            'Name',
            'Thumbnail',
            'Type',
            'Business Type',
            // 'Platform Rights',
            'Language',
            'Provider',
            'Landscape',
            'Portrait',
            'Actions',
        ],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            Type: 'text',
            'Business Type': 'text',
            // 'Platform Rights': 'text',
            Language: 'text',
            Provider: 'text',
            Landscape: 'VISIBLE_ICON',
            Portrait: 'VISIBLE_ICON',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
            // AutoUpdate: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },
    CATEGORY_FLOW_SEARCH: {
        HEADER: ['ID', 'Name', 'Thumbnail', 'Type', 'Source', 'Actions', 'AutoUpdate'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            Type: 'text',
            Source: 'text',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
            AutoUpdate: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },
    CATEGORY_LIST_DATA: {
        HEADER: ['ID', 'Name', 'Thumbnail', 'Type', 'Source', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            Type: 'text',
            Source: 'text',
            Actions: 'CATEGORY_LIST_DATA_ACTION',
        },
    },
    CATEGORY_LIST_EPG_EPISODE: {
        HEADER: ['SrNo', 'Channel Id', 'Title', 'Show Date', 'Start Time', 'End Time', 'Actions', 'AutoUpdate'],
        COMPONENT_TYPE: {
            SrNo: 'ID_ICON',
            'Channel Id': 'text',
            Title: 'text',
            'Show Date': 'text',
            'Start Time': 'text',
            'End Time': 'text',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
            AutoUpdate: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },
    CATEGORY_LIST_EPG_PROGRAM: {
        HEADER: ['ShowId', 'Title', 'Actions', 'AutoUpdate'],
        COMPONENT_TYPE: {
            ShowId: 'ID_ICON',
            Title: 'text',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
            AutoUpdate: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },
    CATEGORY_LIST_EPG_CHANNEL: {
        HEADER: ['woiId', 'Channel', 'Image', 'Actions'],
        COMPONENT_TYPE: {
            woiId: 'ID_ICON',
            Channel: 'text',
            Image: 'IMAGE',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
            // AutoUpdate: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },
    CATEGORY_LIST_DEEPLINK_SEARCH: {
        HEADER: ['ID', 'Name', 'Thumbnail', 'Type', 'Source', 'Actions', 'AutoUpdate'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            Type: 'text',
            Source: 'text',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
            AutoUpdate: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },
    SCHEDULE_RAIL_TABLE: {
        HEADER: ['ID', 'Name', 'Aspect Ratio', 'Type', 'SubType', 'Visible', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            'Aspect Ratio': 'text',
            Type: 'text',
            // Image: 'IMAGE',
            SubType: 'text',
            Visible: 'VISIBLE_ICON',
            Actions: 'SCHEDULE_TABLE_ACTION',
        },
    },
    SCHEDULE_LIST_TABLE: {
        HEADER: ['ID', 'Name', 'Aspect Ratio', 'Type', 'SubType', 'Schedule', 'Visible', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            'Aspect Ratio': 'text',
            Type: 'text',
            //  Image: 'IMAGE',
            SubType: 'text',
            Schedule: 'text',
            Visible: 'VISIBLE_ICON',
            Actions: 'SCHEDULE_LIST_ACTION',
        },
    },
    SCHEDULE_ITEMS_TABLE: {
        HEADER: ['ID', 'Name', 'Thumbnail', 'Content Type', 'Source', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            'Content Type': 'text',
            Source: 'text',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },

    SCHEDULE_ITEMS_LIST_TABLE: {
        HEADER: ['ID', 'Name', 'Thumbnail', 'Content Type', 'Source', 'Schedule', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            'Content Type': 'text',
            Source: 'text',
            Schedule: 'text',
            Actions: 'SCHEDULE_LIST_ACTION',
        },
    },
    DASHBOARD_AUDIT_TABLE: {
        HEADER: ['Description', 'URL', 'Data', 'Time'],
        COMPONENT_TYPE: {
            Description: 'text',
            URL: 'text',
            Data: 'DASHBOARD_LIST_DATA',
            Time: 'text',
        },
    },
    LANG_GENRE_BROADCASTER_TABLE: {
        HEADER: ['ID', 'Name', 'Status', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Status: 'VISIBLE_ICON',
            Actions: 'SCREENS_ACTION',
        },
    },
    LANG_TABLE: {
        HEADER: ['ID', 'Code', 'Name', 'Display Name', 'Status', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Code: 'text',
            Name: 'text',
            'Display Name': 'text',
            Status: 'VISIBLE_ICON',
            Actions: 'SCREENS_ACTION',
        },
    },
    CHANNEL_LIST_TABLE: {
        HEADER: ['ID', 'Logo', 'Name', 'Status', 'isPinned', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Logo: 'IMAGE',
            Name: 'text',
            Status: 'VISIBLE_ICON',
            isPinned: 'VISIBLE_ICON',
            Actions: 'SCREENS_ACTION',
        },
    },
    CHANNEL_REORDER_TABLE: {
        HEADER: ['ID', 'Logo', 'Name'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Logo: 'IMAGE',
            Name: 'text',
        },
    },
    PARENT_CHILD_MAPPING_TABLE: {
        HEADER: ['ID', 'Image', 'Name', 'Status'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Image: 'IMAGE',
            Name: 'text',
            Status: 'VISIBLE_ICON',
        },
    },

    PARENT_CHILD_SEARCH_MAPPING_TABLE: {
        HEADER: ['ID', 'Image', 'Name', 'Status', 'Type', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Image: 'IMAGE',
            Name: 'text',
            Status: 'VISIBLE_ICON',
            Type: 'DROPDOWN_TYPE',
            // isHidden: 'VISIBLE_ICON',
            Actions: 'CATEGORY_LIST_SEARCH_ACTION',
        },
    },
    METADATA: {
        HEADER: [
            'ID',
            'Name',
            'Thumbnail',
            'Content Type',
            'Business Type',
            'Language',
            'Provider',
            'Status',
            'Actions',
        ],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            'Content Type': 'text',
            'Business Type': 'text',
            Language: 'text',
            Provider: 'text',
            Status: 'VISIBLE_ICON',
            Actions: 'METADATA_ACTION',
        },
    },
    METADATA_LIST: {
        HEADER: [
            'ID',
            'Name',
            'Thumbnail',
            'Content Type',
            'Business Type',
            'Language',
            'Provider',
            'Status',
            'Actions',
        ],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Thumbnail: 'IMAGE',
            'Content Type': 'text',
            'Business Type': 'text',
            Language: 'text',
            Provider: 'text',
            Status: 'VISIBLE_ICON',
            Actions: 'METADATA_ACTION',
        },
    },
    CONFIG: {
        HEADER: ['Product', 'Platform', 'Status', 'Actions'],
        COMPONENT_TYPE: {
            Product: 'text',
            Platform: 'text',
            Status: 'VISIBLE_ICON',
            Actions: 'METADATA_ACTION',
        },
    },
    MULTILINGUAL: {
        HEADER: ['ID', 'Source', 'Name', 'Description', 'Language', 'Status'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Source: 'text',
            Name: 'text',
            Description: 'text',
            Language: 'text',
            Status: 'VISIBLE_ICON',
        },
    },
    MULTILINGUAL_CREATE: {
        HEADER: ['ID', 'Name', 'Description', 'Action'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Description: 'text',
            Action: 'MULTILINGUAL_ACTION',
        },
    },

    MASTERCONFIG_LIST: {
        HEADER: ['Key', 'Value', 'Description', 'Updated By', 'Action'],
        COMPONENT_TYPE: {
            Key: 'text',
            Value: 'text',
            Description: 'text',
            'Updated By': 'text',
            Action: 'SCREENS_ACTION',
        },
    },

    FASTCONFIG_LIST: {
        HEADER: [
            'Channel Name',
            'Category Name',
            'Start Duration',
            'End Duration',
            'OverWrite',
            'Repeat',
            'Status',
            'Action',
        ],
        COMPONENT_TYPE: {
            'Channel Name': 'text',
            'Category Name': 'text',
            'Start Duration': 'text',
            'End Duration': 'text',
            OverWrite: 'VISIBLE_ICON',
            Status: 'VISIBLE_ICON',
            Repeat: 'VISIBLE_ICON',
            Action: 'SCREENS_ACTION',
        },
    },
    PARTNERCONFIG_LIST: {
        HEADER: ['ID', 'Type', 'Provider', 'Action'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Type: 'text',
            Provider: 'text',
            Action: 'SCREENS_ACTION',
        },
    },
    EPG_DATA: {
        HEADER: ['SrNo', 'Channel Name', 'Title', 'Show Date', 'Start', 'End', 'Show ID', 'Channel ID', 'Actions'],
        COMPONENT_TYPE: {
            SrNo: 'ID_ICON',
            'Channel Name': 'text',
            Title: 'text',
            'Show Date': 'text',
            Start: 'text',
            End: 'text',
            'Show ID': 'text',
            'Channel ID': 'text',
            Actions: 'METADATA_ACTION',
        },
    },
    TRAILER_EDIT: {
        HEADER: ['Type', 'Content Id', 'Name', 'Actions'],
        COMPONENT_TYPE: {
            Type: 'text',
            'Content Id': 'text',
            Name: 'text',
            Actions: 'SCREENS_ACTION',
        },
    },
    TRAILER_EDIT_MODAL: {
        HEADER: ['Content Id', 'Name', 'Select Action'],
        COMPONENT_TYPE: {
            'Content Id': 'text',
            Name: 'text',
            'Select Action': 'TRAILER_SEARCH_ACTION',
        },
    },

    DEEPLINK_LIST: {
        HEADER: ['ID', 'Name', 'Type', 'Deeplink URL', 'Image', 'Status', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Type: 'text',
            'Deeplink URL': 'DASHBOARD_LIST_DATA',
            Image: 'IMAGE',
            Status: 'VISIBLE_ICON',
            Actions: 'SCREENS_ACTION',
        },
    },
    CTACONFIG_LIST: {
        HEADER: ['ID', 'Name', 'Position', 'Product', 'Platform', 'Screens', 'Visible', 'Actions'],
        COMPONENT_TYPE: {
            ID: 'ID_ICON',
            Name: 'text',
            Position: 'text',
            Product: 'text',
            Platform: 'text',
            Screens: 'text',
            Visible: 'VISIBLE_ICON',
            Actions: 'SCREENS_ACTION',
        },
    },
};
