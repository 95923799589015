import { Component, Input } from '@angular/core';
import { CategoryCarousalModalComponent } from 'src/app/components/modal/category-carousal-modal/category-carousal-modal.component';
import { DeleteConfirmModalComponent } from 'src/app/components/modal/delete-confirm-modal/delete-confirm-modal.component';
import { ModalService } from 'src/app/components/modal/modal.service';
import { CategoryService } from 'src/app/pages/category/category.service';

@Component({
    selector: 'app-ta-type-a',
    templateUrl: './ta-type-a.component.html',
    styleUrls: ['./ta-type-a.component.scss'],
})
export class TATypeAComponent {
    @Input() callbackData: any;
    subType: boolean = true;
    isItem: boolean = false;
    //   @Input() data: any;
    constructor(private modal: ModalService, private categoryService: CategoryService) {}

    ngOnInit() {
        console.log('CALL BACK DATA ', this.callbackData);
        if (this.callbackData[4] == 'Music') {
            this.subType = false;
        }
        if (this.callbackData[5]?.length) {
            this.isItem = true;
        }
    }

    ngOnChanges() {}

    editBtnAction() {
        if (this.callbackData) {
            this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[1]);
        }
    }

    listBtnAction() {
        if (this.callbackData) {
            this.callbackData[1].callback(this.callbackData[1].arg[0], this.callbackData[1].arg[1]);
        }
    }

    visibleModal() {
        if (this.callbackData) {
            this.modal.openModal({
                component: CategoryCarousalModalComponent,
                data: { id: this.callbackData[2].arg },
                prefix: 'category-visible-table-data',
            });
            console.log(this.callbackData);
        }
    }

    deleteBtnAction() {
        if (this.callbackData) {
            this.modal.openModal({
                component: DeleteConfirmModalComponent,
                data: {
                    callbackData: this.callbackData,
                    page: 'categories',
                    text: 'Are you sure you want to delete this ?',
                },
                prefix: 'audit-delete-confirm',
            });
        }
    }
}
