<ng-template #previewLandscapeCard let-item="item">
    <div
        class="card-item"
        [ngClass]="showCollectionBoolean ? 'card-highlight' : ''"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div
            class="wrapper-image"
            style="
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                background-color: black;
                border-radius: var(--radius-medium);
            "
        >
            <img
                class="carousal-item-landscape"
                *ngIf="item.metadata.image"
                src="{{ item.metadata.image }}"
                alt="image"
            />
            <img
                class="carousal-item-landscape"
                *ngIf="item.metadata.bannerArt"
                src="{{ item.metadata.bannerArt }}"
                alt="bannerArt"
            />
            <!-- <img
                class="carousal-item-landscape"
                *ngIf="item.source == 'Cpp'"
                src="{{ item.metadata.bannerArt }}"
                alt="bannerArt"
            />
            <img
                class="carousal-item-landscape"
                *ngIf="item.source == 'Category'"
                src="{{ item.metadata.bannerArt }}"
                alt="bannerArt"
            />
            <img
                class="carousal-item-landscape"
                *ngIf="item.source == 'Epg'"
                src="{{ item.metadata.image }}"
                alt="image"
            /> -->
        </div>
        <div class="wrapper" style="padding-bottom: 30px">
            <div style="padding: 10px; height: 60px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <!-- Best of crime petrol  -->
                    <!-- <span *ngIf="item.metadata.name">
                        {{ item.metadata.name }}
                    </span>
                    <span *ngIf="item.metadata.title">
                        {{ item.metadata.title }}
                    </span> -->
                    <span>
                        {{
                            item.metadata.name
                                ? item.metadata.name
                                : item.metadata?.title || item.metadata?.data?.title || item.metadata?.data?.song
                        }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.metadata.language"> {{ item.metadata.language }} | </span> -->
                    <span> {{ item.metadata.category ? item.metadata.category : item.type }} </span>
                    <span *ngIf="item.metadata.provider"> | {{ item.metadata.provider }} </span>
                </div>
            </div>
            <span *ngIf="isCollection">
                <app-icon
                    *ngIf="!showCollectionBoolean"
                    class="iconLandscape-show"
                    ic="IcChevronDown"
                    size="l"
                    color="var(--color-primary-60)"
                    (click)="showCollectionCard(item)"
                ></app-icon>
                <app-icon
                    *ngIf="showCollectionBoolean"
                    class="iconLandscape-show"
                    ic="IcChevronUp"
                    size="l"
                    color="var(--color-primary-60)"
                    (click)="hideCollectionCard(item)"
                ></app-icon>
            </span>
            <!-- <span>
                <app-icon
                    *ngIf="!showCardBoolean"
                    class="iconLandscape-visible"
                    ic="IcSuccessColored"
                    size="m"
                    (click)="showCard(item)"
                ></app-icon>
                <app-icon
                    *ngIf="showCardBoolean"
                    class="iconLandscape-visible"
                    ic="IcErrorColored"
                    size="m"
                    (click)="removeCard(item)"
                ></app-icon>
            </span> -->
            <app-icon
                class="iconLandscape-calendar"
                ic="IcCalendar"
                size="m"
                fill="var(--color-primary-60)"
                style="--icon-color: var(--color-primary-60); --icon-size: 1.5rem"
            ></app-icon>
            <div
                class="text-body-xxxs"
                [ngClass]="item.schedule == '24x7' ? 'pop-up-landscape-everyday' : 'pop-up-landscape'"
            >
                <div class="pop-up-icon">
                    <app-icon ic="IcCalendar" size="s" fill="var(--color-primary-60)"></app-icon>
                    <span *ngIf="item.schedule == '24x7'">
                        {{ item.schedule }}
                    </span>
                    <span *ngIf="item.schedule !== '24x7'">
                        {{ item.schedule.startdate | date : 'dd/MM/y' }} -
                        {{ item.schedule.enddate | date : 'dd/MM/y' }}
                    </span>
                </div>
                <div class="pop-up-icon">
                    <app-icon *ngIf="item.schedule !== '24x7'" ic="IcTime" size="s" color="#ffffff"></app-icon>
                    <span *ngIf="item.schedule !== '24x7'">
                        {{ item.schedule.starttime }} - {{ item.schedule.endtime }}
                    </span>
                </div>
                <span *ngFor="let day of item.schedule.days; let j = index">
                    {{ day | titlecase }}
                    <span *ngIf="j !== item.schedule.days.length - 1"> | </span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewPortraitCard let-item="item">
    <div
        class="card-item"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div
            class="wrapper-image"
            style="
                position: relative;
                width: 100%;
                padding-bottom: 133.33%;
                background-color: black;
                border-radius: var(--radius-medium);
            "
        >
            <img
                class="carousal-item-portrait"
                *ngIf="item.metadata.image"
                src="{{ item.metadata.image }}"
                alt="image"
            />
            <img
                class="carousal-item-portrait"
                *ngIf="item.metadata.albumArt"
                src="{{ item.metadata.albumArt }}"
                alt="albumArt"
            />
            <!-- <img
                class="carousal-item-portrait"
                *ngIf="item.source == 'Cpp'"
                src="{{ item.metadata.albumArt }}"
                alt="albumArt"
            />
            <img
                class="carousal-item-portrait"
                *ngIf="item.source == 'Category'"
                src="{{ item.metadata.albumArt }}"
                alt="albumArt"
            />
            <img
                class="carousal-item-portrait"
                *ngIf="item.source == 'Epg'"
                src="{{ item.metadata.image }}"
                alt="image"
            /> -->
        </div>
        <div class="wrapper" style="padding-bottom: 30px">
            <div style="padding: 10px; height: 60px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <!-- Best of crime petrol -->
                    <span>
                        {{
                            item.metadata.name
                                ? item.metadata.name
                                : item.metadata?.title || item.metadata?.data?.title || item.metadata?.data?.song
                        }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.metadata.language"> {{ item.metadata.language }} | </span>
                    <span *ngIf="item.metadata.contentType"> {{ item.metadata.contentType }} | </span>
                    <span *ngIf="item.metadata.provider">
                        {{ item.metadata.provider }}
                    </span> -->
                    <span> {{ item.metadata.category ? item.metadata.category : item.type }} </span>
                    <span *ngIf="item.metadata.provider"> | {{ item.metadata.provider }} </span>
                </div>
            </div>
            <span *ngIf="isCollection">
                <app-icon
                    *ngIf="!showCollectionBoolean"
                    class="iconLandscape-show"
                    ic="IcChevronDown"
                    size="l"
                    color="var(--color-primary-60)"
                    (click)="showCollectionCard(item)"
                ></app-icon>
                <app-icon
                    *ngIf="showCollectionBoolean"
                    class="iconLandscape-show"
                    ic="IcChevronUp"
                    size="l"
                    color="var(--color-primary-60)"
                    (click)="hideCollectionCard(item)"
                ></app-icon>
            </span>
            <!-- <span>
                <app-icon
                    *ngIf="!showCardBoolean"
                    class="iconPortrait-visible"
                    ic="IcSuccessColored"
                    size="m"
                    (click)="showCard(item)"
                ></app-icon>
                <app-icon
                    *ngIf="showCardBoolean"
                    class="iconPortrait-visible"
                    ic="IcErrorColored"
                    size="m"
                    (click)="removeCard(item)"
                ></app-icon>
            </span> -->
            <app-icon
                class="iconPortrait-calendar"
                ic="IcCalendar"
                size="m"
                color="var(--color-primary-60)"
                style="--icon-color: var(--color-primary-60); --icon-size: 1.5rem"
            ></app-icon>
            <div
                class="text-body-xxxs"
                [ngClass]="item.schedule == '24x7' ? 'pop-up-portrait-everyday' : 'pop-up-portrait'"
            >
                <div class="pop-up-icon">
                    <app-icon ic="IcCalendar" size="s" fill="var(--color-primary-60)"></app-icon>
                    <span *ngIf="item.schedule == '24x7'">
                        {{ item.schedule }}
                    </span>
                    <span *ngIf="item.schedule !== '24x7'">
                        {{ item.schedule.startdate | date : 'dd/MM/y' }} -
                        {{ item.schedule.enddate | date : 'dd/MM/y' }}
                    </span>
                </div>
                <div class="pop-up-icon">
                    <app-icon *ngIf="item.schedule !== '24x7'" ic="IcTime" size="s" color="#ffffff"></app-icon>
                    <span *ngIf="item.schedule !== '24x7'">
                        {{ item.schedule.starttime }} - {{ item.schedule.endtime }}
                    </span>
                </div>
                <span *ngFor="let day of item.schedule.days; let j = index">
                    {{ day | titlecase }}
                    <span *ngIf="j !== item.schedule.days.length - 1"> | </span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewCircularCard let-item="item">
    <!-- {{item}} -->
    <div
        class="card-item"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div style="margin: 0px 6px">
            <div
                class="wrapper-image"
                style="
                    position: relative;
                    width: 100%;
                    padding-bottom: 100%;
                    border-radius: 50%;
                    background-color: black;
                "
            >
                <img
                    class="carousal-item-circular"
                    *ngIf="item.metadata.image"
                    src="{{ item.metadata.image }}"
                    alt="image"
                />
                <img
                    class="carousal-item-circular"
                    *ngIf="item.metadata.squareArt"
                    src="{{ item.metadata.squareArt }}"
                    alt="squareArt"
                />
                <!-- <img
                    class="carousal-item-circular"
                    *ngIf="item.source == 'Cpp'"
                    src="{{ item.metadata.squareArt }}"
                    alt="squareArt"
                />
                <img
                    class="carousal-item-circular"
                    *ngIf="item.source == 'Category'"
                    src="{{ item.metadata.squareArt }}"
                    alt="squareArt"
                />
                <img
                    class="carousal-item-circular"
                    *ngIf="item.source == 'Epg'"
                    src="{{ item.metadata.image }}"
                    alt="image"
                /> -->
            </div>
        </div>
        <div class="wrapper" style="padding-bottom: 20px">
            <div class="wrapper-text" style="padding: 10px; height: 70px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <!-- Best of crime petrol -->
                    <span>
                        {{
                            item.metadata.name
                                ? item.metadata.name
                                : item.metadata.title || item.metadata.data.title || item.metadata.data.song
                        }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.metadata.language"> {{ item.metadata.language }} | </span>
                    <span *ngIf="item.metadata.contentType"> {{ item.metadata.contentType }} | </span>
                    <span *ngIf="item.metadata.provider">
                        {{ item.metadata.provider }}
                    </span> -->
                    <span> {{ item.metadata.category ? item.metadata.category : item.type }} </span>
                    <span *ngIf="item.metadata.provider"> | {{ item.metadata.provider }} </span>
                </div>
            </div>
            <span *ngIf="isCollection">
                <app-icon
                    *ngIf="!showCollectionBoolean"
                    class="iconLandscape-show"
                    ic="IcChevronDown"
                    size="l"
                    color="var(--color-primary-60)"
                    (click)="showCollectionCard(item)"
                ></app-icon>
                <app-icon
                    *ngIf="showCollectionBoolean"
                    class="iconLandscape-show"
                    ic="IcChevronUp"
                    size="l"
                    color="var(--color-primary-60)"
                    (click)="hideCollectionCard(item)"
                ></app-icon>
            </span>
            <!-- <span>
                <app-icon
                    *ngIf="!showCardBoolean"
                    class="iconCircular-visible"
                    ic="IcSuccessColored"
                    size="m"
                    (click)="showCard(item)"
                ></app-icon>
                <app-icon
                    *ngIf="showCardBoolean"
                    class="iconCircular-visible"
                    ic="IcErrorColored"
                    size="m"
                    (click)="removeCard(item)"
                ></app-icon>
            </span> -->
            <app-icon
                class="iconCircular-calendar"
                ic="IcCalendar"
                size="m"
                color="var(--color-primary-60)"
                style="--icon-color: var(--color-primary-60); --icon-size: 1.5rem"
            ></app-icon>
            <div
                class="text-body-xxxs"
                [ngClass]="item.schedule == '24x7' ? 'pop-up-circular-everyday' : 'pop-up-circular'"
            >
                <div class="pop-up-icon">
                    <app-icon ic="IcCalendar" size="s" fill="var(--color-primary-60)"></app-icon>
                    <span *ngIf="item.schedule == '24x7'">
                        {{ item.schedule }}
                    </span>
                    <span *ngIf="item.schedule !== '24x7'">
                        {{ item.schedule.startdate | date : 'dd/MM/y' }} -
                        {{ item.schedule.enddate | date : 'dd/MM/y' }}
                    </span>
                </div>
                <div class="pop-up-icon">
                    <app-icon *ngIf="item.schedule !== '24x7'" ic="IcTime" size="s" color="#ffffff"></app-icon>
                    <span *ngIf="item.schedule !== '24x7'">
                        {{ item.schedule.starttime }} - {{ item.schedule.endtime }}
                    </span>
                </div>
                <span *ngFor="let day of item.schedule.days; let j = index">
                    {{ day | titlecase }}
                    <span *ngIf="j !== item.schedule.days.length - 1"> | </span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewSubLandscapeCard let-item="item">
    <div
        class="card-item card-highlight"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div
            class="wrapper-image"
            style="
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                background-color: black;
                border-radius: var(--radius-medium);
            "
        >
            <img class="carousal-item-landscape" *ngIf="item.image" src="{{ item.image }}" alt="image" />
            <img class="carousal-item-landscape" *ngIf="item.bannerArt" src="{{ item.bannerArt }}" alt="bannerArt" />
            <!-- <img
                class="carousal-item-landscape"
                *ngIf="item.provider == 'Cpp'"
                src="{{ item.bannerArt }}"
                alt="bannerArt"
            />
            <img
                class="carousal-item-landscape"
                *ngIf="item.provider == 'Category'"
                src="{{ item.bannerArt }}"
                alt="bannerArt"
            />
            <img class="carousal-item-landscape" *ngIf="item.provider == 'Epg'" src="{{ item.image }}" alt="image" /> -->
        </div>
        <div class="wrapper" style="padding-bottom: 30px">
            <div style="padding: 10px; height: 60px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <span>
                        {{ item.title ? item.title : item.name }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.language"> {{ item.language }} | </span> -->
                    <span> {{ item.contentType ? item.contentType : item.category }} </span>
                    <span *ngIf="item.provider"> | {{ item.provider }} </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewSubPortraitCard let-item="item">
    <div
        class="card-item"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div
            class="wrapper-image"
            style="
                position: relative;
                width: 100%;
                padding-bottom: 133.33%;
                background-color: black;
                border-radius: var(--radius-medium);
            "
        >
            <img class="carousal-item-portrait" *ngIf="item.image" src="{{ item.image }}" alt="image" />
            <img class="carousal-item-portrait" *ngIf="item.albumArt" src="{{ item.albumArt }}" alt="albumArt" />
            <!-- <img class="carousal-item-portrait" src="{{ item.albumArt }}" alt="albumArt" /> -->
        </div>
        <div class="wrapper" style="padding-bottom: 30px">
            <div style="padding: 10px; height: 60px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <span>
                        {{ item.title ? item.title : item.name }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.language"> {{ item.language }} | </span>
                    <span *ngIf="item.contentType"> {{ item.contentType }} | </span>
                    <span *ngIf="item.provider">
                        {{ item.provider }}
                    </span> -->
                    <span> {{ item.contentType ? item.contentType : item.category }} </span>
                    <span *ngIf="item.provider"> | {{ item.provider }} </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewSubCircularCard let-item="item">
    <div
        class="card-item"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div style="margin: 0px 6px">
            <div
                class="wrapper-image"
                style="
                    position: relative;
                    width: 100%;
                    padding-bottom: 100%;
                    border-radius: 50%;
                    background-color: black;
                "
            >
                <img class="carousal-item-circular" *ngIf="item.image" src="{{ item.image }}" alt="image" />
                <img class="carousal-item-circular" *ngIf="item.squareArt" src="{{ item.squareArt }}" alt="squareArt" />
                <!-- <img class="carousal-item-circular" src="{{ item.squareArt }}" alt="squareArt" /> -->
            </div>
        </div>
        <div class="wrapper" style="padding-bottom: 20px">
            <div class="wrapper-text" style="padding: 10px; height: 70px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <span>
                        {{ item.title ? item.title : item.name }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.language"> {{ item.language }} | </span>
                    <span *ngIf="item.contentType"> {{ item.contentType }} | </span>
                    <span *ngIf="item.provider">
                        {{ item.provider }}
                    </span> -->
                    <span> {{ item.contentType ? item.contentType : item.category }} </span>
                    <span *ngIf="item.provider"> | {{ item.provider }} </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewPdpLandscapeCard let-item="item">
    <div
        class="card-item card-highlight"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div
            class="wrapper-image"
            style="
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                background-color: black;
                border-radius: var(--radius-medium);
            "
        >
            <img class="carousal-item-landscape" *ngIf="item.image" src="{{ item.image }}" alt="image" />
            <img class="carousal-item-landscape" *ngIf="item.bannerArt" src="{{ item.bannerArt }}" alt="bannerArt" />
            <!-- <img
                class="carousal-item-landscape"
                *ngIf="item.provider == 'Cpp'"
                src="{{ item.bannerArt }}"
                alt="bannerArt"
            />
            <img
                class="carousal-item-landscape"
                *ngIf="item.provider == 'Category'"
                src="{{ item.bannerArt }}"
                alt="bannerArt"
            />
            <img class="carousal-item-landscape" *ngIf="item.provider == 'Epg'" src="{{ item.image }}" alt="image" /> -->
        </div>
        <div class="wrapper" style="padding-bottom: 30px">
            <div style="padding: 10px; height: 60px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <span>
                        {{ item.title ? item.title : item.name }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.language"> {{ item.language }} | </span> -->
                    <span> {{ item.contentType ? item.contentType : item.category }} </span>
                    <span *ngIf="item.provider"> | {{ item.provider }} </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewPdpPortraitCard let-item="item">
    <div
        class="card-item"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div
            class="wrapper-image"
            style="
                position: relative;
                width: 100%;
                padding-bottom: 133.33%;
                background-color: black;
                border-radius: var(--radius-medium);
            "
        >
            <img class="carousal-item-portrait" *ngIf="item.image" src="{{ item.image }}" alt="image" />
            <img class="carousal-item-portrait" *ngIf="item.albumArt" src="{{ item.albumArt }}" alt="albumArt" />
            <!-- <img class="carousal-item-portrait" src="{{ item.albumArt }}" alt="albumArt" /> -->
        </div>
        <div class="wrapper" style="padding-bottom: 30px">
            <div style="padding: 10px; height: 60px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <span>
                        {{ item.title ? item.title : item.name }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.language"> {{ item.language }} | </span>
                    <span *ngIf="item.contentType"> {{ item.contentType }} | </span>
                    <span *ngIf="item.provider">
                        {{ item.provider }}
                    </span> -->
                    <span> {{ item.contentType ? item.contentType : item.category }} </span>
                    <span *ngIf="item.provider"> | {{ item.provider }} </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #previewPdpCircularCard let-item="item">
    <div
        class="card-item"
        style="box-shadow: var(--shadow-s); background-color: #ffffff; border-radius: var(--radius-medium)"
    >
        <div style="margin: 0px 6px">
            <div
                class="wrapper-image"
                style="
                    position: relative;
                    width: 100%;
                    padding-bottom: 100%;
                    border-radius: 50%;
                    background-color: black;
                "
            >
                <img class="carousal-item-circular" *ngIf="item.image" src="{{ item.image }}" alt="image" />
                <img class="carousal-item-circular" *ngIf="item.squareArt" src="{{ item.squareArt }}" alt="squareArt" />
                <!-- <img class="carousal-item-circular" src="{{ item.squareArt }}" alt="squareArt" /> -->
            </div>
        </div>
        <div class="wrapper" style="padding-bottom: 20px">
            <div class="wrapper-text" style="padding: 10px; height: 70px">
                <div class="ellipsis text-heading-xxs" style="color: black">
                    <span>
                        {{ item.title ? item.title : item.name }}
                    </span>
                </div>
                <div class="ellipsis text-body-xxs" style="color: black">
                    <!-- <span *ngIf="item.language"> {{ item.language }} | </span>
                    <span *ngIf="item.contentType"> {{ item.contentType }} | </span>
                    <span *ngIf="item.provider">
                        {{ item.provider }}
                    </span> -->
                    <span> {{ item.contentType ? item.contentType : item.category }} </span>
                    <span *ngIf="item.provider"> | {{ item.provider }} </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="orientation == 'Landscape' && cardType == 'previewPage'">
    <ng-container *ngTemplateOutlet="previewLandscapeCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Portrait' && cardType == 'previewPage'">
    <ng-container *ngTemplateOutlet="previewPortraitCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Square' && cardType == 'previewPage'">
    <ng-container *ngTemplateOutlet="previewCircularCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Landscape' && cardType == 'previewSubPage'">
    <ng-container *ngTemplateOutlet="previewSubLandscapeCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Portrait' && cardType == 'previewSubPage'">
    <ng-container *ngTemplateOutlet="previewSubPortraitCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Square' && cardType == 'previewSubPage'">
    <ng-container *ngTemplateOutlet="previewSubCircularCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Landscape' && cardType == 'pdpPage'">
    <ng-container *ngTemplateOutlet="previewPdpLandscapeCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Portrait' && cardType == 'pdpPage'">
    <ng-container *ngTemplateOutlet="previewPdpPortraitCard; context: { item: item }"></ng-container>
</ng-container>

<ng-container *ngIf="orientation == 'Square' && cardType == 'pdpPage'">
    <ng-container *ngTemplateOutlet="previewPdpCircularCard; context: { item: item }"></ng-container>
</ng-container>
