<div class="j-field" style="padding-top: var(--spacing-xxs)">
    <input
        class="j-input"
        placeholder=" "
        [type]="inputType"
        (ngModelChange)="updateFormChanges($event)"
        [ngModel]="enteredText"
        [disabled]="disable"
    />
    <span class="j-placeholder" for="">{{ placeholder }}</span>
</div>
