import { Component, Input } from '@angular/core';
import { DeleteConfirmModalComponent } from 'src/app/components/modal/delete-confirm-modal/delete-confirm-modal.component';
import { ImageModalComponent } from 'src/app/components/modal/image-modal/image-modal.component';
import { ModalService } from 'src/app/components/modal/modal.service';
import { SpinnerService } from 'src/app/components/spinner/spinner.service';

@Component({
    selector: 'app-ta-type-image-delete',
    templateUrl: './ta-type-image-delete.component.html',
    styleUrls: ['./ta-type-image-delete.component.scss'],
})
export class TaTypeImageDeleteComponent {
    @Input() callbackData: any;
    subType: boolean = true;
    //   @Input() data: any;
    constructor(private modal: ModalService) {}

    ngOnInit() {
        console.log('callback image', this.callbackData);

        // if (this.callbackData[4] == 'Music') {
        //     this.subType = false;
        // }
    }

    ngOnChanges() {}

    imageModal() {
        console.log(this.callbackData);
        if (this.callbackData) {
            this.modal.openModal({
                component: ImageModalComponent,
                data: this.callbackData,
                prefix: 'image-modal-data',
            });
            console.log(this.callbackData);
        }
    }

    deleteBtnAction() {
        console.log('deleteBtnAction', this.callbackData);

        if (this.callbackData) {
            this.callbackData[1].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[2]);
        }
    }
}
