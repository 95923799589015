import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { Subject, Observable } from 'rxjs';
import { tap, catchError, shareReplay, map } from 'rxjs/operators';
import { AppConstants } from '../constants/app-constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    private authData: any;
    private fileUploadSubject = new Subject();

    fileUpload$: Observable<any> = this.fileUploadSubject.asObservable();

    constructor(private auth: AuthService, private http: HttpService) {
        this.authData = this.auth.getAuthData();
    }

    getUploadedImagePath(collectionType: string, imageType: string, uploadedFile: any) {
        const url = `${environment.apiUrlDomain}/images/upload?collection=${collectionType}&imageType=${imageType}`;

        const headers = {
            'x-access-token': this.authData.token,
        };
        let formData = new FormData();
        formData.append('file', uploadedFile);

        this.http
            .postRequest(url, headers, formData)
            .pipe(
                tap((response: any) => {
                    if (response.status == 201) {
                        this.fileUploadSubject.next({
                            type: 'FILE_UPLOAD_IMAGE_PATH_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.fileUploadSubject.next({
                        type: 'FILE_UPLOAD_IMAGE_PATH_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }

    deleteImagePath(imageId: any, type: any, id: any) {
        console.log('IMAGE ID', imageId, type);

        let payload = imageId.substring(imageId.indexOf('category'));
        console.log('MAIN ID', payload);

        const url = `${environment.apiUrlDomain}/categories/deleteCategoryImage/`;
        const headers = {
            'x-access-token': this.authData.token,
        };
        let body: any = {};

        if (id == 0) {
            body = {
                [type]: payload,
            };
        } else {
            body = {
                [type]: payload,
                id: id,
            };
        }

        console.log('TYPE', body);
        this.http
            .deleteRequest(url, headers, true, body)
            .pipe(
                tap((response: any) => {
                    if (response.status == 202) {
                        this.fileUploadSubject.next({
                            type: 'FILE_DELETE_IMAGE_SUCCESSFUL',
                            data: response.body,
                        });
                    }
                }),
                catchError((error) => {
                    this.fileUploadSubject.next({
                        type: 'FILE_DELETE_IMAGE_FAILED',
                        data: error,
                    });
                    throw error;
                }),
                shareReplay()
            )
            .subscribe();
    }
}
