import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
    @Input() labelText!: string;
    @Output() setImgSrc = new EventEmitter<any>();
    filename: string = '';
    private fileReader!: FileReader;
    private uploadedFile: any;
    @Input() imgSrc: any = null;

    constructor() {
        this.fileReader = new FileReader();
        this.fileReader.addEventListener(
            'load',
            () => {
                // convert image file to base64 string
                this.imgSrc = this.fileReader.result;
                this.setImgSrc.emit({
                    label: this.labelText,
                    img: this.imgSrc,
                    uploadedFile: this.uploadedFile,
                });
            },
            false
        );
    }

    ngOnInit(): void { }

    upload(uploadedFile: any) {
        this.uploadedFile = uploadedFile;
        this.filename = uploadedFile.name;
        this.fileReader.readAsDataURL(uploadedFile);
    }
}
