import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalhostDirective } from 'src/app/directives/modalhost/modalhost.directive';
import { IconModule } from '../icon/icon.module';
import { DeleteConfirmModalComponent } from './delete-confirm-modal/delete-confirm-modal.component';
import { CategoryCarousalModalComponent } from './category-carousal-modal/category-carousal-modal.component';
import { CarousalModule } from '../carousal/carousal.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { AddRemoveDataComponent } from './add-remove-data/add-remove-data.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { TextFieldModule } from '../text-field/text-field.module';
import { FormsModule } from '@angular/forms';
import { TrailerEditModalComponent } from './trailer-edit-modal/trailer-edit-modal.component';
import { SearchModule } from '../search/search.module';
import { TableModule } from '../table/table.module';
import { PaginationModule } from '../pagination/pagination.module';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { RadioModule } from '../radio/radio.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { DataModalComponent } from './data-modal/data-modal.component';
import { DeeplinkPlayerModalComponent } from './deeplink-player-modal/deeplink-player-modal.component';

@NgModule({
    declarations: [
        ModalComponent,
        ModalhostDirective,
        DeleteConfirmModalComponent,
        CategoryCarousalModalComponent,
        AddRemoveDataComponent,
        TrailerEditModalComponent,
        ImageModalComponent,
        DataModalComponent,
        DeeplinkPlayerModalComponent,
    ],
    imports: [
        CommonModule,
        IconModule,
        CarousalModule,
        SpinnerModule,
        DropdownModule,
        TextFieldModule,
        FormsModule,
        SearchModule,
        TableModule,
        PaginationModule,
        RadioModule,
        FileUploadModule,
    ],
    exports: [ModalComponent, ModalhostDirective],
})
export class ModalModule {}
