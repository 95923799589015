import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../toast/toast.service';
import { MultilingualService } from 'src/app/pages/multilingual/multilingual.service';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';


@Component({
  selector: 'app-add-remove-data',
  templateUrl: './add-remove-data.component.html',
  styleUrls: ['./add-remove-data.component.scss']
})
export class AddRemoveDataComponent {

  @Input() data: any;

  multilingualSubscription$?: Subscription;

  modalData: any = [];

  tempData: any = [];

  removedData: any = [];

  refId: any;

  source: any;

  isSubmit: boolean = false;

  languageDropdown: any = {
    label: '--Language--',
    options: [
      { label: '--Language--', value: '' },
      { label: 'Option 1', value: '' },
      { label: 'Option 2', value: '' },
    ],
  };

  selectedLanguageType: string = '';

  constructor(
    private multilingualService: MultilingualService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toast: ToastService,
    private modal: ModalService,
  ) { }

  ngOnInit() {
    console.log('Data', this.data);
    console.log('Removed Data', this.removedData);
    this.multilingualSubscription$ = this.multilingualService.multilingual$.subscribe((params: any) => {
      if (params) {
        switch (params.type) {
          case 'MULTILINGUAL_GET_ADD_DATA_SUCCESSFUL':
            const multiData = params.data.languageData;
            this.refId = params.data.refId;
            console.log(params.data);
            this.modalData = multiData.map((data: any) => {
              const language = Object.keys(data)[0]
              return {
                name: data[language].name,
                description: data[language].description,
                language: language,
                languageId: data[language].languaeId
              };
            });
            console.log(this.modalData);
            this.tempData = [...this.modalData];
            break;

          case 'MULTILINGUAL_LANGUAGE_TYPE_SUCCESSFUL':
            console.log(params.data.data);
            let languages = params.data.data;
            let languagesData = languages.map((lang: any) => {
              return { label: lang.name, value: lang.id };
            });
            languagesData.unshift({ label: '--Language--', value: '' });
            this.languageDropdown = { label: '--Language--', options: languagesData };
            break;

          case 'MULTILINGUAL_CREATE_EDIT_SUCCESSFUL':
            console.log('Inside it', params.type);
            this.toast.showToast('Update Successful', 'success');
            this.modal.closeModal();
            break;
        }
      }
    });
    this.multilingualService.getDataOnAdd(this.data.data.id);
    this.multilingualService.getLanguageTypes();
  };

  addRow() {
    this.tempData.push({
      name: '',
      description: '',
      language: '',
      languageId: ''
    });
  }

  deleteRow(index: any, item: any) {
    const found = this.modalData.find((obj: any) => obj.languageId == item.languageId)
    if (found) {
      this.removedData.push(item.languageId);
    }
    this.tempData.splice(index, 1);
  }

  submit() {
    this.isSubmit = this.tempData.every((item: any) => item.name !== '' && item.languageId !== '');
    if (this.isSubmit) {
      const filteredData = this.tempData.map((item: any) => {
        return { name: item.name, description: item.description, languageId: item.languageId };
      });
      const finalData = {
        refId: this.refId,
        source: this.data.data.source,
        data: filteredData,
        remove: this.removedData
      }
      console.log('Service calling');
      console.log(finalData);

      this.multilingualService.createEditMultilingual(finalData);
    }
    else {
      this.toast.showToast('Name and Language both are required fields', 'error');
    }


    console.log(this.tempData);

  }

  getLanguageData(item: any) {
    return {
      label: item.language === '' ? '--Language--' : item.language,
      options: this.languageDropdown.options,
    }

  }

  selectLanguageType(event: any, index: any) {
    // this.selectedLanguageType = event.data;
    this.tempData[index].languageId = event.data;
    this.tempData[index].language = this.languageDropdown.options.find((item: any) => item.value == event.data).label;
    console.log(event.data);
  }

  ngOnDestroy() {
    this.multilingualSubscription$?.unsubscribe();
    // this.routeSubscription$?.unsubscribe();
  }
}
