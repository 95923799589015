import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextFieldComponent),
            multi: true,
        },
    ],
})
export class TextFieldComponent implements ControlValueAccessor {
    @Input() placeholder!: any;
    @Input() inputType!: string;
    @Input() disable!: boolean;
    
    enteredText: any;

    // functions related to ControlValueAccessor
    propagateChange = (_: any) => {};

    // this writes data from form model to the view
    writeValue(value: any) {
        if (value !== undefined) {
            this.enteredText = value;
        }
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {}

    updateFormChanges(event: any) {
        this.enteredText = event;
        this.propagateChange(event);
    }
}
