<div *ngIf="type1" class="j-search">
    <span class="j-search__icon">
        <app-icon ic="IcSearch" size="m" color="#000000"></app-icon>
    </span>
    <input
        [formControl]="searchKey"
        autocomplete="off"
        [placeholder]="placeholderText"
        type="text"
        name="searchKey"
        class="j-search__text text-body-s"
        (keyup)="search()"
    />
</div>

<div *ngIf="type2" class="search">
    <span class="search__icon">
        <app-icon ic="IcSearch" size="m" color="#000000"></app-icon>
    </span>
    <input placeholder="Search" type="text" class="search__text text-body-s" />
</div>
