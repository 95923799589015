import { Component, Input } from '@angular/core';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
    selector: 'app-deeplink-player-modal',
    templateUrl: './deeplink-player-modal.component.html',
    styleUrls: ['./deeplink-player-modal.component.scss'],
})
export class DeeplinkPlayerModalComponent {
    @Input() data: any;
    modalData: any = [];
    header: any;
    @Input() showSpinner: boolean = true;

    // categorySubscription$?: Subscription;

    dataFetched: boolean = false;

    constructor(private spinner: SpinnerService) {}

    ngOnInit() {
        console.log(this.data);
    }
}
