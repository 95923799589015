import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class LoginPageGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(): boolean {
        var authdata = undefined;
        authdata = this.authService.getAuthData();
        if (authdata) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
