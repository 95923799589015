import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { TruncateFileName } from 'src/app/utils/truncate-filename.pipe';

@NgModule({
    declarations: [FileUploadComponent, TruncateFileName],
    imports: [CommonModule],
    exports: [FileUploadComponent, TruncateFileName],
})
export class FileUploadModule {}
