export const IconSizesList = ['s', 'm', 'l', 'xl', 'xxl', 'fill'] as const;
export type IconSizes = (typeof IconSizesList)[number];
export const ICON_SIZE: { [key in NonNullable<IconSizes>]: string } = {
    fill: 'inherit',
    s: '1rem',
    m: '1.5rem',
    l: '2rem',
    xl: '2.5rem',
    xxl: '3rem',
};
export const ICON_SIZE_SVG: { [key in NonNullable<IconSizes>]: string } = {
    fill: 'inherit',
    s: '1rem',
    m: '1rem',
    l: '1.5rem',
    xl: '1.75rem',
    xxl: '2rem',
};
