import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarousalComponent } from './carousal.component';
import { IconModule } from '../icon/icon.module';
import { CardsModule } from '../cards/cards.module';

@NgModule({
    declarations: [CarousalComponent],
    imports: [CommonModule, IconModule, CardsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [CarousalComponent],
})
export class CarousalModule {}
