<div class="j-dropdown">
    <div class="j-dropdown__label j-text-field" style="padding-bottom: 4px" (click)="show()">
        <div class="j-dropdown__labelType">
            <div *ngIf="selectedOptionText != displayLabel && isDeeplink" class="j-dropdown__labelText">
                {{ displayLabel }}
            </div>
            <div class="j-dropdown__selected-option">
                {{ selectedOptionText }}
            </div>
        </div>
        <!-- <div class="j-dropdown__selected-option">
            {{ selectedOptionText }}
        </div> -->
        <div class="j-dropdown__chevron-icon">
            <span [ngClass]="!drop ? 'visible' : 'not-visible'">
                <app-icon ic="IcChevronDown" size="m" color="#000000"></app-icon>
            </span>
            <span [ngClass]="drop ? 'visible' : 'not-visible'">
                <app-icon ic="IcChevronUp" size="m" color="#000000"></app-icon>
            </span>
        </div>
    </div>
    <ul class="j-dropdown__list text-body-s">
        <ng-container *ngIf="!isMultiSelect">
            <li
                class="j-dropdown__list-item"
                *ngFor="let item of data?.options; let i = index"
                [ngClass]="drop ? 'visible' : 'not-visible'"
                (click)="selectOption(data.options[i].label, data.options[i].value, i)"
            >
                {{ data.options[i].label }}
            </li>
        </ng-container>

        <ng-container *ngIf="isMultiSelect">
            <li
                class="j-dropdown__list-item j-dropdown__list-item--multiselect"
                *ngFor="let item of data?.options; let i = index"
                [ngClass]="drop ? 'visible' : 'not-visible'"
                (click)="selectOption(data.options[i].label, data.options[i].value, i)"
            >
                <span> {{ data.options[i].label }} </span>
                <span *ngIf="selectedOptionIndexes[item.value]?.checked" style="float: right">
                    <app-icon ic="IcSuccessColored" size="s"></app-icon>
                </span>
            </li>
        </ng-container>
    </ul>
</div>
