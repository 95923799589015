import { Component, Input, ElementRef, Renderer2 } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DeleteConfirmModalComponent } from '../modal/delete-confirm-modal/delete-confirm-modal.component';
import { ModalService } from '../modal/modal.service';

@Component({
    selector: 'app-toggler',
    templateUrl: './toggler.component.html',
    styleUrls: ['./toggler.component.scss'],
})
export class TogglerComponent {
    @Input() callbackData: any;
    @Input() isChecked: any;
    constructor(private modal: ModalService) {}

    status: any;

    ngOnInit() {
        // this.status = this.callbackData[0].arg[2] == 1 ? true : false;
        this.isChecked = this.callbackData[0].arg[2] == 1;
    }

    toggle(event: Event) {
        event.preventDefault();
        if (this.callbackData && this.callbackData[0].arg[3] === 'screensManageEdit') {
            console.log('Managing');
            console.log(this.callbackData);

            const callbackData = [
                {
                    arg: this.callbackData[0].arg,
                    callback: (...args: any[]) => {
                        this.isChecked = !this.isChecked;
                        console.log('TOGGLED');
                        this.callbackData[0].callback(...args);
                    },
                },
            ];
            console.log('after modifying', callbackData);

            this.modal.openModal({
                component: DeleteConfirmModalComponent,
                data: {
                    callbackData,
                    page: 'screensManageEdit',
                    text: 'Are you sure you want to change status ?',
                },
                prefix: 'audit-delete-confirm',
            });
        }

        console.log('is Checked', this.isChecked, this.callbackData);
    }
}
