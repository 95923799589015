import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConstants } from 'src/app/constants/app-constants';

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss'],
})
export class CardsComponent {
    @Input() item: any;
    @Input() orientation: string | undefined;
    @Input() cardType: string | undefined;
    @Input() isCollection: any;
    @Input() showCollectionBoolean: boolean = false;
    @Output() showCardEvent = new EventEmitter<any>();
    @Output() removeCardEvent = new EventEmitter<any>();
    @Output() showCarousalEvent = new EventEmitter<any>();
    @Output() hideCarousalEvent = new EventEmitter<any>();

    showCardBoolean: boolean = false;
    // showCollectionCarousal: boolean = false;

    ngOnInit() {
        console.log(this.showCollectionBoolean);
        console.log(this.item);
        // console.log('orientation', this.orientation);
    }

    // ngOnChanges() {
    //     this.showCardBoolean = false;
    // }

    showCard(data: any) {
        console.log('showCardData here', data);
        this.showCardBoolean = true;
        this.showCardEvent.emit(data);
    }
    removeCard(data: any) {
        this.showCardBoolean = false;
        this.removeCardEvent.emit(data);
    }

    showCollectionCard(item: any) {
        this.showCollectionBoolean = true;
        const data = { item: item, orientation: this.orientation };
        this.showCarousalEvent.emit(data);
    }

    hideCollectionCard(item: any) {
        this.showCollectionBoolean = false;
        const data = { item: item, orientation: this.orientation };
        this.hideCarousalEvent.emit(data);
    }

    getImageUrl(url: any) {
        return url;
    }
}
