import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TrailerEditService } from 'src/app/pages/trailer-edit/trailer-edit.service';

@Component({
    selector: 'app-trailer-edit-modal',
    templateUrl: './trailer-edit-modal.component.html',
    styleUrls: ['./trailer-edit-modal.component.scss'],
})
export class TrailerEditModalComponent {
    @Input() data: any;

    trailerModalData: any = [];
    trailerSubscription$?: Subscription;

    searchKey: any;
    header: any;
    dataFetched: boolean = false;
    selectedContent: any;
    updateId: any;
    contentId: any;
    flag: any = false;

    totalCount: any;
    pageSize: any;
    pageCount: any;
    pageNo: any;
    currentCount: any;

    contentTypeDropdown: any = {
        label: '--Select Content Type--',
        options: [
            { label: '--Select Content Type--', value: '' },
            { label: 'Trailer', value: 'Trailer' },
            { label: 'Video', value: 'Video' },
        ],
    };
    constructor(private TrailerEditService: TrailerEditService) {}

    ngOnInit() {
        // this.typeOfTrailer = this.data

        console.log('TRAILER TYPE', this.data.id[3], this.data);

        this.trailerSubscription$ = this.TrailerEditService.traileredit$.subscribe((params: any) => {
            if (params) {
                switch (params.type) {
                    case 'TRAILER_SEARCH_GET_SUCCESSFUL':
                        const trailerContentData = params.data.data;
                        console.log('TRAILER SEARCH DATATA', trailerContentData);
                        let checked = false;

                        this.trailerModalData = trailerContentData.map((item: any) => {
                            return {
                                'Content Id': item.contentId,
                                Name: item.name,
                                'Select Action': [
                                    {
                                        arg: [item.contentId, item.id, item, this],
                                        callback: this.processRadio,
                                    },
                                    { arg: checked },
                                ],
                            };
                        });
                        if (this.trailerModalData.length > 0) {
                            this.flag = true;
                        }

                        console.log(this.trailerModalData);
                        const paginationData = params.data.paging;
                        this.totalCount = paginationData.totalCount;
                        this.pageSize = paginationData.pageSize;
                        this.pageCount = paginationData.pageCount;
                        this.pageNo = paginationData.pageNo;
                        break;
                }
            }
        });
    }

    onSearched(searchKey: any) {
        console.log(searchKey);
        this.searchKey = searchKey;
    }

    selectContentType(event: any) {
        this.selectedContent = event.data;
        console.log(event.data);
    }

    searchFilter() {
        this.TrailerEditService.getDataBySearch(this.selectedContent, 1, this.searchKey);
    }

    update() {
        console.log('THIS IS WORKING', this.updateId, this.contentId, this.data.id[3]);
        this.TrailerEditService.updateTrailer(this.updateId, this.data.id[3], this.data.id[0]);
    }

    processRadio = (id: string, checked: boolean, contentId: string) => {
        if (checked == true) {
            console.log('checked', checked, id);
            this.updateId = id;
            this.contentId = contentId;
            console.log(this.updateId, this.contentId);
        }
    };

    //pagination functions
    onGoTo(page: number): void {
        this.pageNo = page;
        this.paginate(this.pageNo);
    }
    onNext(page: number): void {
        this.pageNo = page + 1;
        this.paginate(this.pageNo);
    }
    onPrevious(page: number): void {
        this.pageNo = page - 1;
        this.paginate(this.pageNo);
    }
    paginate(pageNo: number) {
        // if (this.searchKey) {
        this.TrailerEditService.getDataBySearch(this.selectedContent, pageNo, this.searchKey);
        // } else {
        //     this.TrailerEditService.getDataBySearch(this.selectedContent,pageNo,this.searchKey);
        // }
    }
}
