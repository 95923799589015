import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    host: {
        '(document:click)': 'onClick($event)',
    },
})
export class DropdownComponent {
    @Input() disable!: boolean;
    @Input() isMultiSelect: boolean = false;
    @Input() isDeeplink!: boolean;
    @Input() displayLabel: string = '';

    @Input() defaultOptionIndex!: number;
    @Input() data: any = {
        label: 'Select Option',
        options: [
            { label: 'Option 1', value: 'Option 1' },
            { label: 'Option 2', value: 'Option 2' },
            { label: 'Option 3', value: 'Option 3' },
            { label: 'Option 4', value: 'Option 4' },
        ],
    };

    @Input() initializeMultiSelect: any;

    @Output() selectedItemEvent = new EventEmitter<any>();

    drop: boolean = false;
    selectedOptionText: string = 'Select Option';
    selectedOptionIndex: number = 0;

    /* the below is for multiselect dropdown */
    selectedOptionIndexes: any = {};

    constructor(private eref: ElementRef) {}

    ngOnInit() {}

    ngOnChanges() {
        this.selectedOptionText = this.data?.label;
        this.selectedOptionIndexes = {};
        if (this.isMultiSelect && this.initializeMultiSelect?.length > 0) {
            for (let index = 0; index < this.initializeMultiSelect.length; index++) {
                this.selectOption(
                    this.initializeMultiSelect[index].label,
                    this.initializeMultiSelect[index].value,
                    index
                );
            }
        }
        /* this will select the dropdown item which has the index defaultOptionIndex */
        /* TODO: need a better way to handle below logic */
        if (!this.isMultiSelect) {
            if (this.defaultOptionIndex < this.data?.options?.length) {
                const defaultOption = this.data.options[this.defaultOptionIndex];
                this.selectOption(defaultOption.label, defaultOption.value, this.defaultOptionIndex);
                this.drop = false;
            }
        }
    }

    show() {
        if (!this.disable) {
            this.drop = !this.drop;
        }
    }

    selectOption(label: string, value: string, index: number) {
        if (!this.isMultiSelect) {
            this.selectedOptionText = label;
            this.selectedOptionIndex = index;
            this.show();
            this.selectedItemEvent.emit({
                index: index,
                data: value,
            });
        } else {
            if (value == '') {
                this.selectedOptionText = label;
                this.selectedItemEvent.emit({
                    isMultiSelect: true,
                    data: '',
                });
            } else {
                if (this.selectedOptionIndexes[value]) {
                    if (this.selectedOptionIndexes[value].checked == true) {
                        delete this.selectedOptionIndexes[value];
                    } else {
                        this.selectedOptionIndexes[value] = { index: index, label: label, value: value, checked: true };
                    }
                } else {
                    this.selectedOptionIndexes[value] = { index: index, label: label, value: value, checked: true };
                }

                this.selectedOptionText = '';
                Object.values(this.selectedOptionIndexes).forEach((data: any, index: number) => {
                    if (data.checked == true) {
                        this.selectedOptionText = this.selectedOptionText
                            ? `${this.selectedOptionText}, ${data.label}`
                            : data.label;
                    }
                });

                if (this.selectedOptionText == '') {
                    this.selectedOptionText = this.data.options[0].label;

                    this.selectedItemEvent.emit({
                        isMultiSelect: true,
                        data: '',
                    });
                } else {
                    this.selectedItemEvent.emit({
                        isMultiSelect: true,
                        data: this.selectedOptionIndexes,
                    });
                }
            }
        }
    }

    onClick(event: any) {
        if (!this.eref.nativeElement.contains(event.target)) {
            // or some similar check
            this.drop = false;
        }
    }
}
