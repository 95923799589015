import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
    @Input() toastMessage: string = 'Default Toast Message';
    @Input() appear: boolean = true;
    @Input() messageType: string = 'success';

    constructor() {}

    ngOnInit(): void {}
}
