import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
    type1: boolean = true;
    type2: boolean = false;

    searchKey = new FormControl();

    @Input() placeholderText: string = 'Search';
    @Input() reset: number = 0;
    @Output() searched: EventEmitter<string> = new EventEmitter<string>();

    constructor(private elementRef: ElementRef) {}

    ngOnChanges() {
        if (this.reset > 0) {
            this.searchKey.setValue('');
        }
    }

    search() {
        this.searched.emit(this.searchKey.value);
    }
}
