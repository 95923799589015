import { Component, Input } from '@angular/core';
import { DataModalComponent } from 'src/app/components/modal/data-modal/data-modal.component';
import { ModalService } from 'src/app/components/modal/modal.service';

@Component({
    selector: 'app-ta-type-imagedata',
    templateUrl: './ta-type-imagedata.component.html',
    styleUrls: ['./ta-type-imagedata.component.scss'],
})
export class TaTypeImagedataComponent {
    @Input() data: any;

    stringOfWords: any;

    headers: any = [];

    modalData: any;

    constructor(private modal: ModalService) {}

    ngOnInit() {
        this.headers = Object.keys(this.data.data);
        this.modalData = JSON.stringify(this.data.data);
        let count = 100;
        this.stringOfWords = '';
        if (this.headers.length > 0) {
            for (const char of this.modalData) {
                if (count > 0) {
                    count = count - 1;
                    this.stringOfWords += char;
                } else {
                    break;
                }
            }
        }
        console.log(this.stringOfWords);
    }

    showModal() {
        this.modal.openModal({
            component: DataModalComponent,
            data: this.data,
            prefix: 'audit-table-data',
        });
    }
}
