import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio.component';

@NgModule({
    declarations: [RadioComponent],
    imports: [CommonModule],
    exports: [RadioComponent],
})
export class RadioModule {}
