<div class="modalContainer" *ngIf="imageModalData">
    <div *ngFor="let item of imageModalData; let i = index">
        <div class="modalContainer__content">
            <img class="modalContainer__image" src="{{ item.image }}" alt="Image {{ i + 1 }}" />
            <app-radio
                class="modalContainer__radio"
                value="{{ item._id || item.index }}"
                (radioChange)="radioChanged($event)"
            ></app-radio>
        </div>
    </div>
    <div class="modalContainer__browse">
        <button type="button" class="j-button" (click)="fileinput.click()">
            <span class="text-button">Browse</span>
            <input
                type="file"
                style="display: none"
                #fileinput
                (change)="upload(fileinput?.files?.[0])"
                accept="image/*"
            />
        </button>
    </div>
    <div class="modalContainer__submit" *ngIf="imageModalData != '' && this.submitId">
        <button class="j-button j-button--type-1" type="submit" (click)="submit()" style="width: var(--spacing-xl)">
            <span class="text-button">Submit</span>
        </button>
    </div>
    <div class="modalContainer__spinner" *ngIf="showSpinner">
        <app-spinner [isTable]="true"></app-spinner>
    </div>
</div>
<div class="no-data-found" *ngIf="imageModalData == '' && dataFetched">
    <div class="no-data-found__text text-heading-m">No Data Found</div>
</div>
