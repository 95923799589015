import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpinnerService } from '../spinner/spinner.service';
import { TableConfig } from './table.config';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent {
    // header: any = ['Id', 'Name', 'Visibility'];
    @Input() tableName!: string;
    @Input() tableData: any;

    tableHeader: any;
    tableComponentType: any;
    // isSpinner: boolean = false;
    @Input() showSpinner: boolean = true;

    @Input() rowDraggable: boolean = false;
    @Output() dragTableDateUpdate = new EventEmitter<any>();

    constructor(private spinner: SpinnerService) {}

    ngOnInit(): void {
        // this.showSpinner = true;
        // this.spinner.isTable = true;
        this.tableHeader = TableConfig[this.tableName].HEADER;
        this.tableComponentType = TableConfig[this.tableName].COMPONENT_TYPE;
    }

    ngOnChanges(): void {
        this.showSpinner = true;
        if (this.tableData?.length != 0) {
            setTimeout(() => {
                this.showSpinner = false;
                // this.spinner.isTable = false;
            }, 1000);
        } else {
            setTimeout(() => {
                this.showSpinner = false;
                // this.spinner.isTable = false;
            }, 3000);
        }
    }

    // categorySpinner(showSpinner){
    //     this.showSpinner = true;
    //     if (this.tableData.length != 0) {
    //         setTimeout(() => {
    //             this.showSpinner = false;
    //             // this.spinner.isTable = false;
    //         }, 1000);
    //     }
    // }

    typeof(val: any) {
        return typeof val;
    }

    drop(event: any) {
        moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
        this.dragTableDateUpdate.emit(this.tableData);
    }
}
