import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalComponent } from './modal.component';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private subject = new Subject();

    modalObservable$: Observable<any> = this.subject.asObservable();

    constructor() {}

    openModal(params: any) {
        const modalObj = { component: params.component, modalData: params.data, prefix: params.prefix, state: true };
        this.subject.next(modalObj);
    }

    closeModal() {
        const modalObj = { state: false };
        this.subject.next(modalObj);
    }
}
