<div>
    <div>
        <p style="margin-top: var(--spacing-xs); text-align: center; margin-bottom: 0px" class="text-body-s">
            {{ data.text }}
        </p>
    </div>
    <div style="text-align: center; position: relative; top: 40px">
        <button class="j-button j-button--type-1" type="submit" (click)="confirm()" style="width: var(--spacing-xl)">
            <span class="text-button">Yes</span>
        </button>
    </div>
</div>
