<ng-template #previewPageCarousal let-carousalData="carousalData">
    <ng-container>
        <div class="carousal-label text-heading-xs">
            <span>
                {{ carousalData?.category?.name }}
            </span>
            <app-icon
                class="label-calendar"
                ic="IcCalendar"
                size="m"
                color="var(--color-primary-60)"
                (click)="getData(carousalData.schedule)"
            ></app-icon>
            <span>
                <app-icon
                    *ngIf="!iconLabelData"
                    class="label-visibleOff"
                    ic="IcSuccessColored"
                    size="m"
                    (click)="showLabelId(carousalData)"
                ></app-icon>
                <app-icon
                    *ngIf="iconLabelData"
                    class="label-visibleOff"
                    ic="IcErrorColored"
                    size="m"
                    (click)="removeLabelId(carousalData)"
                ></app-icon>
            </span>
            <div
                class="text-body-xxxs"
                [ngClass]="carousalData.schedule == '24x7' ? 'pop-up-label-everyday' : 'pop-up-label'"
            >
                <div class="pop-up-icon">
                    <app-icon ic="IcCalendar" size="s" color="#ffffff"></app-icon>
                    <span *ngIf="carousalData.schedule == '24x7'">
                        {{ carousalData.schedule }}
                    </span>
                    <span *ngIf="carousalData.schedule !== '24x7'">
                        {{ carousalData.schedule.startdate | date : 'dd/MM/y' }} -
                        {{ carousalData.schedule.enddate | date : 'dd/MM/y' }}
                    </span>
                </div>
                <div class="pop-up-icon">
                    <app-icon *ngIf="carousalData.schedule !== '24x7'" ic="IcTime" size="s" color="#ffffff"></app-icon>
                    <span *ngIf="carousalData.schedule !== '24x7'">
                        {{ carousalData.schedule.starttime }} - {{ carousalData.schedule.endtime }}
                    </span>
                </div>
                <span *ngFor="let day of carousalData.schedule.days; let j = index">
                    {{ day | titlecase }}
                    <span *ngIf="j !== carousalData.schedule.days.length - 1"> | </span>
                </span>
            </div>
        </div>
        <jio-carousal [settings]="settings" [responsive_settings]="settings?.responsive_settings">
            <div class="wrapper" slot="item" *ngFor="let item of carousalData.items; let j = index">
                <app-cards
                    [orientation]="carousalData?.category?.itemOrientation"
                    cardType="previewPage"
                    [item]="item"
                    [showCollectionBoolean]="iconShowData[j]"
                    [isCollection]="isCollection"
                    (showCardEvent)="showCardId(carousalData.categoryId, $event, j)"
                    (removeCardEvent)="removeCardId(carousalData.categoryId, $event, j)"
                    (showCarousalEvent)="showCollectionCard($event, j)"
                    (hideCarousalEvent)="hideCollectionCard($event, j)"
                ></app-cards>
            </div>
        </jio-carousal>
    </ng-container>
</ng-template>

<ng-template #previewPageSubCarousal let-carousalData="carousalData">
    <!-- <ng-container>
        <div class="carousal-label text-heading-xs">
            <span>
                {{ carousalData.category.name }}
            </span>
            <app-icon class="label-calendar" ic="IcCalendar" size="m" color="var(--color-primary-60)"
                (click)="getData(carousalData.schedule)"></app-icon>
            <span>
                <app-icon *ngIf="!iconLabelData" class="label-visibleOff" ic="IcSuccessColored" size="m"
                    (click)="showLabelId(carousalData)"></app-icon>
                <app-icon *ngIf="iconLabelData" class="label-visibleOff" ic="IcErrorColored" size="m"
                    (click)="removeLabelId(carousalData)"></app-icon>
            </span>
            <div class="text-body-xxxs"
                [ngClass]="carousalData.schedule == '24x7' ? 'pop-up-label-everyday' : 'pop-up-label'">
                <div class="pop-up-icon">
                    <app-icon ic="IcCalendar" size="s" color="#ffffff"></app-icon>
                    <span *ngIf="carousalData.schedule == '24x7'">
                        {{ carousalData.schedule }}
                    </span>
                    <span *ngIf="carousalData.schedule !== '24x7'">
                        {{ carousalData.schedule.startdate | date : 'dd/MM/y' }} -
                        {{ carousalData.schedule.enddate | date : 'dd/MM/y' }}
                    </span>
                </div>
                <div class="pop-up-icon">
                    <app-icon *ngIf="carousalData.schedule !== '24x7'" ic="IcTime" size="s" color="#ffffff"></app-icon>
                    <span *ngIf="carousalData.schedule !== '24x7'">
                        {{ carousalData.schedule.starttime }} - {{ carousalData.schedule.endtime }}
                    </span>
                </div>
                <span *ngFor="let day of carousalData.schedule.days; let j = index">
                    {{ day | titlecase }}
                    <span *ngIf="j !== carousalData.schedule.days.length - 1"> | </span>
                </span>
            </div>
        </div> -->
    <jio-carousal [settings]="settings" [responsive_settings]="settings.responsive_settings">
        <div
            class="wrapper-subCarousal"
            slot="item"
            *ngFor="let item of carousalData.item.items; let j = index"
            [ngClass]="carousalData.item.items ? 'show-subCarousal' : ''"
        >
            <app-cards
                [orientation]="carousalData.orientation"
                cardType="previewSubPage"
                [item]="item"
                (showCardEvent)="showCardId(item.id, $event, j)"
                (removeCardEvent)="removeCardId(item.id, $event, j)"
                (showCarousalEvent)="showCollectionCard($event, j)"
                (hideCarousalEvent)="hideCollectionCard($event, j)"
            ></app-cards>
        </div>
    </jio-carousal>
    <!-- </ng-container> -->
</ng-template>

<ng-template #pdpPage let-carousalData="carousalData">
    <div class="carousal-label text-heading-xs">
        <span>
            {{ carousalData?.category?.name }}
        </span>
    </div>
    <jio-carousal [settings]="settings" [responsive_settings]="settings.responsive_settings">
        <div
            class="wrapper-subCarousal"
            slot="item"
            *ngFor="let item of carousalData?.category?.itemsData; let j = index"
            [ngClass]="carousalData.category.items ? 'show-subCarousal' : ''"
        >
            <app-cards
                [orientation]="carousalData?.category?.itemOrientation"
                cardType="pdpPage"
                [item]="item"
                (showCardEvent)="showCardId(item.id, $event, j)"
                (removeCardEvent)="removeCardId(item.id, $event, j)"
                (showCarousalEvent)="showCollectionCard($event, j)"
                (hideCarousalEvent)="hideCollectionCard($event, j)"
            ></app-cards>
        </div>
    </jio-carousal>
    <!-- </ng-container> -->
</ng-template>

<ng-container [ngSwitch]="pageType">
    <ng-container *ngSwitchCase="'previewPage'">
        <ng-container *ngTemplateOutlet="previewPageCarousal; context: { carousalData: carousalData }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'previewPageSubCarousal'">
        <ng-container
            *ngTemplateOutlet="previewPageSubCarousal; context: { carousalData: carousalData }"
        ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'pdpPage'">
        <ng-container *ngTemplateOutlet="pdpPage; context: { carousalData: carousalData }"></ng-container>
    </ng-container>
</ng-container>
