<div class="spinner-container" *ngIf="!isTable">
    <div class="j-spinner-circles">
        <svg class="circle circle-0" viewBox="0 0 28 28">
            <circle fill="none" stroke-width="4" stroke-linecap="round" cx="14" cy="14" r="12" />
        </svg>
        <svg class="circle circle-1" viewBox="0 0 28 28">
            <circle fill="none" stroke-width="4" stroke-linecap="round" cx="14" cy="14" r="12" />
        </svg>
    </div>
    <div class="spinner-backdrop"></div>
</div>

<div class="spinner-container-2" *ngIf="isTable">
    <div class="j-spinner-circles-2">
        <svg class="circle circle-0" viewBox="0 0 28 28">
            <circle fill="none" stroke-width="4" stroke-linecap="round" cx="14" cy="14" r="12" />
        </svg>
        <svg class="circle circle-1" viewBox="0 0 28 28">
            <circle fill="none" stroke-width="4" stroke-linecap="round" cx="14" cy="14" r="12" />
        </svg>
    </div>
</div>
