import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast.component';
import { ToasthostDirective } from 'src/app/directives/toasthost/toasthost.directive';
import { IconModule } from '../icon/icon.module';

@NgModule({
    declarations: [ToastComponent, ToasthostDirective],
    imports: [CommonModule, IconModule],
    exports: [ToastComponent, ToasthostDirective],
})
export class ToastModule {}
