<div>
    <ng-container>
        <span>
            {{ stringOfWords }}
        </span>
    </ng-container>
    <span *ngIf="headers.length != 0" class="icon-span" (click)="showModal()">
        <app-icon ic="IcInfo" size="m" color="var(--color-primary-60)"></app-icon>
    </span>
</div>
