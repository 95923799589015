import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ta-type-e',
    templateUrl: './ta-type-e.component.html',
    styleUrls: ['./ta-type-e.component.scss'],
})
export class TaTypeEComponent {
    @Input() callbackData: any;
    /* if the checkbox is selected to true then only enable the schedule items page */
    // lockListBtnAction: boolean = true;
    showTooltip: boolean = false;

    listBtnAction() {
        // if (this.callbackData && !(this.lockListBtnAction)) {
        if (!this.callbackData[1].arg[3]) {
            this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[1]);
        }
        // } else {
        //   this.showTooltip = true;
        //   setTimeout(() => {
        //     this.showTooltip = false;
        //   }, 1500);
        // }
    }

    checkboxChanged(event: any) {
        // this.lockListBtnAction = !(event.checked);
        if (!this.callbackData[1].arg[3]) {
            this.callbackData[1].callback(
                this.callbackData[1].arg[0],
                event.checked,
                this.callbackData[1].arg[1],
                this.callbackData[1].arg[2]
            );
        }
    }

    onClick() {
        if (this.callbackData[1].arg[3]) {
            this.showTooltip = true;
            setTimeout(() => {
                this.showTooltip = false;
            }, 2000);
        }
    }
}
