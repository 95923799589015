import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
    selector: 'app-delete-confirm-modal',
    templateUrl: './delete-confirm-modal.component.html',
    styleUrls: ['./delete-confirm-modal.component.scss'],
})
export class DeleteConfirmModalComponent {
    @Input() data: any;
    constructor(private modal: ModalService) {}

    ngOnInit() {
        // JSON.stringify(this.data, null, '\t');
        // this.data = JSON.stringify(this.data, null, 4);
        console.log('in delete', this.data);
    }

    confirm() {
        if (this.data.page == 'screens') {
            this.data.callbackData[1].callback(this.data.callbackData[1].arg[0], this.data.callbackData[1].arg[1]);
        }
        if (this.data.page == 'categories') {
            this.data.callbackData[3].callback(this.data.callbackData[3].arg[0], this.data.callbackData[3].arg[1]);
        }
        if (this.data.page == 'screensManageEdit') {
            this.data.callbackData[0].callback(this.data.callbackData[0].arg[0], this.data.callbackData[0].arg[1]);
        }
        if (this.data.page == 'trailer') {
            this.data.callbackData[1].callback(this.data.callbackData[0].arg[0], this.data.callbackData[0].arg[3]);
        }
        if (this.data.page == 'categoriesList') {
            this.data.callbackData[1].callback(this.data.callbackData[0].arg[0], this.data.callbackData[0].arg[1]);
        }
        if (this.data.page == 'deeplink') {
            console.log('DEEPLINK');
            this.data.callbackData[1].callback(this.data.callbackData[1].arg[0], this.data.callbackData[1].arg[1]);
        }
        if (this.data.page == 'parentChild') {
            console.log(this.data.callbackData[0]);
            this.data.callbackData[0].callback(
                this.data.callbackData[0].arg[0],
                true,
                this.data.callbackData[0].arg[1]
            );
            // this.data.callbackData.callback();
        }

        console.log(this.data);
        this.modal.closeModal();
    }
}
