import { Component, Input } from '@angular/core';
import { DeleteConfirmModalComponent } from 'src/app/components/modal/delete-confirm-modal/delete-confirm-modal.component';
import { ModalService } from 'src/app/components/modal/modal.service';

@Component({
    selector: 'app-ta-type-md',
    templateUrl: './ta-type-md.component.html',
    styleUrls: ['./ta-type-md.component.scss'],
})
export class TaTypeMdComponent {
    @Input() callbackData: any;
    constructor(private modal: ModalService) {}
    subType: boolean = false;

    ngOnInit() {
        if (this.callbackData[0].arg[3] == 'Show') {
            this.subType = true;
        }
    }

    editBtnAction() {
        console.log('callback', this.callbackData);
        if (this.callbackData[0].arg[2] == 'epgdata') {
            console.log('EPG DATA CALLBACK', this.callbackData);
            this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[1]);
        }
        if (this.callbackData[0].arg[2] == 'metadata') {
            this.callbackData[0].callback(this.callbackData[0].arg[0], this.callbackData[0].arg[1]);
        }
        if (this.callbackData[0].arg[2] == 'screensManageEdit') {
            console.log('Managing');

            this.modal.openModal({
                component: DeleteConfirmModalComponent,
                data: {
                    callbackData: this.callbackData,
                    page: 'screensManageEdit',
                    text: 'Are you sure you want to edit status ?',
                },
                prefix: 'audit-delete-confirm',
            });
        }
    }

    listBtnAction() {
        console.log(this.callbackData);

        if (this.callbackData) {
            this.callbackData[1].callback(this.callbackData[1].arg[0], this.callbackData[1].arg[1]);
        }
    }
}
