export class AppSettings {
    public static CAROUSAL_SETTINGS = {
        carousal_l: {
            CENTER_MODE: false,
            CENTER_MODE_PADDING: 0.3,
            DRAG_ENABLE: true,
            FREESCROLL_MODE: false,
            DOTTED_MODE: false,
            INFINITE_MODE: false,
            VISIBLE_ITEMS: 5,
            AUTOPLAY: { AUTOPLAY_ENABLE: false },
            NAVIGATION_STATE: { NAVIGATION_ENABLE: true },
            responsive_settings: [
                { BREAKPOINT: 768, VISIBLE_ITEMS: 3 },
                { BREAKPOINT: 1000, VISIBLE_ITEMS: 6 },
                { BREAKPOINT: 480, VISIBLE_ITEMS: 2 },
            ],
        },

        carousal_p: {
            CENTER_MODE: false,
            CENTER_MODE_PADDING: 0.3,
            DRAG_ENABLE: true,
            FREESCROLL_MODE: false,
            DOTTED_MODE: false,
            INFINITE_MODE: false,
            VISIBLE_ITEMS: 6,
            AUTOPLAY: { AUTOPLAY_ENABLE: false },
            NAVIGATION_STATE: { NAVIGATION_ENABLE: true },
            responsive_settings: [
                { BREAKPOINT: 768, VISIBLE_ITEMS: 3 },
                { BREAKPOINT: 1000, VISIBLE_ITEMS: 6 },
                { BREAKPOINT: 480, VISIBLE_ITEMS: 2 },
            ],
        },

        carousal_s: {
            CENTER_MODE: false,
            CENTER_MODE_PADDING: 0.3,
            DRAG_ENABLE: true,
            FREESCROLL_MODE: false,
            DOTTED_MODE: false,
            INFINITE_MODE: false,
            VISIBLE_ITEMS: 6,
            AUTOPLAY: { AUTOPLAY_ENABLE: false },
            NAVIGATION_STATE: { NAVIGATION_ENABLE: true },
            responsive_settings: [
                { BREAKPOINT: 768, VISIBLE_ITEMS: 3 },
                { BREAKPOINT: 1000, VISIBLE_ITEMS: 6 },
                { BREAKPOINT: 480, VISIBLE_ITEMS: 2 },
            ],
        },

        // carousal_banner: {
        //     CENTER_MODE: true,
        //     CENTER_MODE_PADDING: 0.1,
        //     DRAG_ENABLE: true,
        //     FREESCROLL_MODE: false,
        //     DOTTED_MODE: false,
        //     INFINITE_MODE: false,
        //     VISIBLE_ITEMS: 1,
        //     AUTOPLAY: { AUTOPLAY_ENABLE: false },
        //     NAVIGATION_STATE: { NAVIGATION_ENABLE: true },
        //     responsive_settings: [
        //         { BREAKPOINT: 768, VISIBLE_ITEMS: 3 },
        //         { BREAKPOINT: 1000, VISIBLE_ITEMS: 6 },
        //         { BREAKPOINT: 480, VISIBLE_ITEMS: 2 },
        //     ],
        // },
    };
}
