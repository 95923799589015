<div class="wrapper">
    <div class="wrapper__info text-body-s">
        <span> <b>Name:</b> {{ data.data.name }} | </span>
        <span> <b>Description</b> {{ data.data.description | slice : 0 : 35 }}... </span>
        <!-- <span> <b>Language:</b> {{ data.data.language }} </span> -->
    </div>
    <div class="wrapper__header text-body-s">
        <div>
            <span>Name*</span>
        </div>
        <div>
            <span>Description</span>
        </div>
        <div>
            <span>Language*</span>
        </div>
        <!-- <div class="modal-spinner-container" *ngIf="showSpinner">
                <app-spinner [isTable]="true"></app-spinner>
            </div> -->
        <!-- </div> -->
        <div style="display: flex;  align-items: center; justify-content: center;" (click)="addRow()">
            <app-icon ic="IcAddCircle" size="s" color="var(--color-primary-60)"></app-icon>
        </div>
    </div>
    <div class="wrapper__body" *ngFor="let item of tempData; let i = index;">
        <!-- <div class="row row-cols-3" style="--grid-gutter-width: var(--spacing-s);  padding: var(--spacing-s); "> -->
        <div>
            <app-text-field placeholder="Name" [(ngModel)]='item.name'></app-text-field>
        </div>
        <div>
            <app-text-field placeholder="Description" [(ngModel)]='item.description'></app-text-field>
        </div>
        <div>
            <app-dropdown [data]="getLanguageData(item)"
                (selectedItemEvent)="selectLanguageType($event, i)"></app-dropdown>
        </div>
        <!-- <div class="modal-spinner-container" *ngIf="showSpinner">
                <app-spinner [isTable]="true"></app-spinner>
            </div> -->
        <!-- </div> -->
        <div style="display: flex;  align-items: center; justify-content: center;" (click)="deleteRow(i, item)">
            <app-icon ic="IcMinusCircle" size="s" color="var(--color-primary-60)"></app-icon>
        </div>
    </div>

</div>

<div style="text-align: right; position: relative; top: 20px">
    <button class="j-button j-button--type-1" type="submit" (click)="submit()" style="width: var(--spacing-m)">
        <span class="text-button">Submit</span>
    </button>
</div>

<!-- <div class="no-data-found" *ngIf="modalData == '' && dataFetched">
    <div class="no-data-found__text text-heading-m">
        No Data Found
    </div>
</div> -->